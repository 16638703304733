<template>
  <c-link
    ref="btn"
    :class="classes"
    :style="buttonStyles"
    :disabled="disabled || loading"
    v-bind="$attrs"
    v-on="$listeners"
    @click.native="onClick()"
  >
    <div
      data-testid="badge-container"
      v-if="hasNotifications"
      class="badge-container"
      :class="{
        '-container-count': notifications?.count,
        '-three-digit': notifications?.count > 99
      }"
    >
      <div
        class="badge"
        :class="{
          '-badge-count': notifications?.count,
          '-badge-no-count': !notifications?.count,
        }">
        <span
          data-testid="notification"
          class="notification">{{ notifications?.count > 99 ? '+99' : notifications?.count }}</span>
      </div>
    </div>

    <c-icon
      data-testid="icon"
      v-if="icon"
      :icon="icon"
      :icon-pkg="iconPkg"
      :size="iconSize"
      class="icon"
    />

    <span v-if="$slots.default" class="text">
      <!-- @slot The content of the button -->
      <slot />
    </span>

    <!-- @slot Used to add content without the .text class styles -->
    <slot name="raw" />

    <transition name="loading">
      <c-loader v-if="loading" size="25" class="loader" />
    </transition>

    <div class="border -one" />

    <div class="border -two" />

    <div class="border -three" />

    <div class="border -four" />
  </c-link>
</template>

<script>

/**
 * The standard button used throught convenia projects.
 */
export default {
  name: 'CButton',

  emits: [
    /**
    * The button click event.
    */
    'click',
  ],

  props: {

    /**
    * An object representing the notification section.
     * @type {Object}
     * @property {boolean} showOnlyHover - Show or not the notification section with hover effect.
     * @property {number} count - The notification counter.
     */
    notifications: {
      type: Object,
      default: () => ({}),
    },

    /**
    * The height of the button.
    */
    size: {
      type: [ String, Number ],
      default: 40,
      validator: size => typeof +size === 'number' && +size > 0
    },

    /**
     * The minWidth of the button
     */
    minWidth: {
      type: [ String, Number ],
      default: null,
      validator: size => typeof +size === 'number' && +size > 0
    },

    /**
     * The width of the button
     */
    width: {
      type: [ String, Number ],
      default: null,
      validator: size => typeof +size === 'number' && +size > 0
    },

    /**
     * The icon to show in the right side (or in the center if no text)
     * of the button. Optional.
     */
    icon: {
      type: String,
      default: ''
    },

    iconPkg: {
      type: String,
      default: 'convenia'
    },

    /**
     * The size of the icon.
     */
    iconSize: {
      type: [ String, Number ],
      default: '20'
    },

    /**
     * Makes the background slightly lighter when disabled. Use this prop
     * when placing the CButton on an element with dark bg with opacity.
     */
    alternativeDisabled: Boolean,

    /**
     * Makes the button grow to fill the entire width of the container.
     */
    fullWidth: Boolean,

    /**
     * Disables the button.
     */
    disabled: Boolean,

    /**
     * A large button with bigger padding and font-size.
     */
    jumbo: Boolean,

    /**
     * Sets the bg-color of the button to the primary convenia color.
     */
    primary: Boolean,

    /**
     * Sets the bg-color of the button to the sucess convenia color.
     */
    success: Boolean,

    /**
     * Sets the bg-color of the button to the error convenia color.
     */
    error: Boolean,

    /**
     * Sets the bg-color of the button to the info convenia color.
     */
    info: Boolean,

    /**
     * Sets the bg-color of the button to the info convenia color.
     */
    alert: Boolean,

    /**
     * Makes the button's appearance flat, with a transparent bg.
     */
    flat: Boolean,

    /**
    * Makes the button's appearance flat, with a blur bg.
    */
    flatBlur: Boolean,

    /**
     * Displays a loader (spinner) on the button
     */
    loading: Boolean,

    /**
     * Sets the bg-color of the button to white.
     */
    white: Boolean,

    /**
     * Makes the button have the same appearance of a link.
     */
    ghost: Boolean,

    /**
     * Makes the background grey ($text-color, base-30).
     */
    grey: Boolean,

    /**
     * Makes the background translucent rgba(#FFF, .3)
     */
    translucent: Boolean,

    /**
     * Makes the button's border style dashed
     */
    dashed: Boolean,

    /**
     * Makes the button's border style solid ($text-color, base-10).
     */
    flatBorder: Boolean,

    /**
     * Adds a background shawdow to the button (bg variant in zeroheight)
     */
    shadow: Boolean,

    /**
    * Adds beta tag alongside the text slot
    */
    beta: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      clicked: false,
      ratio: 1
    }
  },

  computed: {
    classes () {
      return [ 'c-button',
        {
          '-disabled': this.disabled,
          '-alternative-disabled': this.alternativeDisabled,
          '-jumbo': this.jumbo,
          '-has-icon': this.icon && this.$slots.default,
          '-icon-only': !this.$slots.default,
          '-full-width': this.fullWidth,
          '-primary': this.primary || (!this.success && !this.error && !this.info
            && !this.alert && !this.white && !this.ghost && !this.grey),
          '-success': this.success,
          '-error': this.error,
          '-info': this.info,
          '-alert': this.alert,
          '-white': this.white,
          '-ghost': this.ghost,
          '-flat': this.flat,
          '-flat-blur': this.flatBlur,
          '-grey': this.grey,
          '-translucent': this.translucent,
          '-dashed': this.dashed,
          '-loading': this.loading,
          '-clicked': this.clicked,
          '-flat-border': this.flatBorder,
          '-shadow': this.shadow,
          '-beta': this.beta,
          '-show-notifications-on-hover': this.hasNotifications && this.notifications?.showOnlyHover,
        }
      ]
    },

    hasNotifications () {
      const keys = Object.keys(this.notifications)

      return keys.includes('count') && keys.includes('showOnlyHover')
    },

    buttonSize () {
      return this.jumbo ? 60 : this.size
    },

    buttonStyles () {
      const minWidth = this.minWidth || this.size

      return !this.ghost ? {
        height: `${+this.buttonSize}px`,
        minHeight: `${+this.buttonSize}px`,
        minWidth: `${+minWidth}px`,
        borderRadius: this.fullWidth ? '0px' : `${+this.buttonSize}px`,
        ...(this.width ? { width: `${+this.width}px` } : {})
      } : { }
    }
  },

  methods: {
    onClick () {
      if (this.clicked) return

      this.clicked = true
      setTimeout(() => { this.clicked = false }, 500)
    }
  }
}
</script>

<style lang="scss">
.c-button {
  position: relative;
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: color-var(primary);
  background-repeat: no-repeat;
  cursor: pointer;
  outline: none;
  padding: 0 40px;
  transition: background-color .3s,
              opacity .3s,
              box-shadow .3s,
              border .3s;

  // Moving borders animation
  @include pulse-animation(26px);

  & > .badge-container {
      position: absolute;

      &::before {
        content: "";
        display: block;
        height: 120%;
        width: 115%;
        position: absolute;
        z-index: 10;
        right: -1px;
        top: -1px;
        border: solid;
        border-width: medium;
        border-radius: 50px;
        border-color: white;
      }

      & > .badge {
        @include typo(h5, base-50);
        border-radius: 50px;
        background: #FF4B8C;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 20;
        box-shadow: 0 2px 6px 0 rgba(255, 75, 140, .5);
      }

      & > .badge.-badge-count {
        height: 18px;
        min-width: 18px;
        padding-right: 5px;
        padding-left: 5px;
      }

      & > .badge:not(.-badge-count) {
        width: 8px;
        height: 8px;
        min-width: 0;
        padding: 0;
      }
    }

    & > .badge-container.-container-count {
      top: -5px;
      right: -10px;
    }

    & > .badge-container:not(.-container-count) {
      top: -1px;
      right: -1px;
    }

    & > .badge-container.-three-digit {
      &::before {
        width: 110%;
        border-color: white;
      }
    }

  & > .border {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: inherit;
    border: 1px black solid;
    opacity: 0;
    animation-play-state: paused;
    flex: 1;
  }

  &.-clicked {
    & > .border { animation-play-state: running; }
    & > .-one { animation: pulse-1 .3s linear forwards; }
    & > .-two { animation: pulse-2 .3s 60ms linear forwards; }
    & > .-three { animation: pulse-3 .3s 120ms linear forwards; }
    & > .-four { animation: pulse-4 .3s .180ms linear forwards; }
  }

  // bright layer for hover effect
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    left: 0;
    top: 0;
    background: #fff;
    opacity: 0;
    transition: opacity .3s;
    flex: 1;
  }

  @include responsive (tablet, desktop) {
    &:hover::before { filter: blur(14px); opacity: 1; }
    &:hover::after { opacity: 0.1;}
    &:active::after { opacity: 0; }
  }

  & > .icon {
    flex-shrink: 0;
    transition: fill .3s ease, stroke .3s ease, filter .3s;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, .3));

    @include icon-color(rgba(#FFF, .5));
  }

  &.-has-icon {
    padding: 0 45px 0 35px;

    & > .text { padding-left: 5px; }
    & > .icon { left: 20px; @include icon-color(rgba(#FFF, .5)); }
  }

  & > .text {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    font-family: var(--title-font-family);
    color: #FFF;
    line-height: 14px;
    white-space: nowrap;

    transition: color 300ms ease, opacity .3s;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  & > .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));
      path { fill: #fff; }
    }
  }

  &.-jumbo {
    padding: 0 50px;
    & > .text { font-size: 13px; }
  }

  &.-icon-only {
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;

    & > .icon { @include icon-color(#FFF); filter: unset; }
  }

  &.-primary {
    background: color-var();
    box-shadow: 0px 9px 13px -6px rgba(color-var(primary, base-rgb), 0.5);

    &:hover { box-shadow: 0px 4px 22px -2px rgba(var(--color-primary-base-rgb), 0.6) }
    & > .border { border-color: color-var(); }
  }

  &.-success {
    background: color-var(positive);
    box-shadow: 0px 9px 13px -6px rgba(color-var(positive, base-rgb), 0.5);

    &:hover { box-shadow: 0px 4px 22px -2px rgba(color-var(positive, base-rgb), 0.6) }
    & > .border { border-color: color-var(positive); }
  }

  &.-error {
    background: color-var(negative);
    box-shadow: 0px 9px 13px -6px rgba(color-var(negative, base-rgb), 0.5);

    &:hover { box-shadow: 0px 4px 22px -2px rgba(color-var(negative, base-rgb), 0.6) }
    & > .border { border-color: color-var(negative); }
  }

  &.-info {
    background: color-var(info);
    box-shadow: 0px 9px 13px -6px rgba(color-var(info, base-rgb), 0.5);

    &:hover { box-shadow: 0px 4px 22px -2px rgba(color-var(info, base-rgb), 0.6) }
    & > .border { border-color: color-var(info); }
  }

  &.-alert {
    background: color-var(alert);
    box-shadow: 0px 9px 13px -6px rgba(color-var(alert, base-rgb), 0.5);

    &:hover { box-shadow: 0px 4px 22px -2px rgba(color-var(alert, base-rgb), 0.6) }
    & > .border { border-color: color-var(alert); }
  }

  &.-white {
    background: #FFF;
    box-shadow: 0px 9px 13px -6px rgba(#FFF, 0.5);

    & > .border { border-color: color-var(text, base-50); }
    & > .icon {
      @include icon-color(text, base-50);
      filter: none;
    }
    & > .text { color: color-var(text, base-50); }

    &:hover { box-shadow: 0px 4px 22px -2px rgba(#FFF, 0.6) }
  }

  &.-grey {
    background: color-var(text, base-30);

    box-shadow: 0 3px 10px -4px color-var(text, base-30);
    & > .border { display: none; }
  }

  &.-translucent {
    background: rgba(#FFF, .3);
    &, &:hover { box-shadow: none };

    & > .text { color: rgba(#FFF, .8); }
    & > .icon { @include icon-color(rgba(#FFF, .8)); }

    &::after { display: none; }
    & > .border { display: none; }

    @include responsive (tablet, desktop) {
      &:hover:not(.-disabled) { background: rgba(#FFF, .5); }
    }
  }

  &.-flat {
    background: transparent;
    border: 1px solid color-var(text, base-10);
    &, &:hover { box-shadow: none; }

    & > .border { border-color: color-var(); }
    & > .icon { @include icon-color(text, base-50); filter: unset; }
    & > .text { color: color-var(text, base-50); }
    & > .loader svg {
      filter: unset;
      path { fill: color-var(text, base-50); }
    }

    @include responsive (tablet, desktop) {
      &:hover {
        border: 1px solid color-var();
        box-shadow:
          0px 0px 3px 0px rgba(color-var(primary, base-rgb), 0.55),
          inset 0px 0px 3px 0px rgba(color-var(primary, base-rgb), 0.55);

        & > .icon { @include icon-color(text, base-80); }
        & > .text { color: color-var(text, base-80); }
        & > .loader svg path { fill: color-var(text, base-80); }
      }
    }
  }

  &.-flat-blur {
    border: transparent;
    background-color: color-var(text, base-10);
    &, &:hover { box-shadow: none; }

    & > .border { border-color: 1px solid rgba(#FFF, .3); }
    & > .icon { @include icon-color(text, base-50); filter: unset; }
  }

  &.-flat.-success {
    & > .border { border-color: color-var(positive); }
    & > .icon { @include icon-color(positive); }

    @include responsive (tablet, desktop) {
      &:hover {
        border: 1px solid color-var(positive);
        box-shadow:
          0px 0px 3px 0px rgba(color-var(positive, base-rgb), 0.55),
          inset 0px 0px 3px 0px rgba(color-var(positive, base-rgb), 0.55);
      }
    }
  }

  &.-flat.-error {
    & > .border { border-color: color-var(negative); }
    & > .icon { @include icon-color(negative); }

    @include responsive (tablet, desktop) {
      &:hover {
        border: 1px solid color-var(negative);
        box-shadow:
          0px 0px 3px 0px rgba(color-var(negative, base-rgb), 0.55),
          inset 0px 0px 3px 0px rgba(color-var(negative, base-rgb), 0.55);
      }
    }
  }

  &.-flat.-info {
    & > .border { border-color: color-var(info); }
    & > .icon { @include icon-color(info); }

    @include responsive (tablet, desktop) {
      &:hover {
        border: 1px solid color-var(info);
        box-shadow:
          0px 0px 3px 0px rgba(color-var(info, base-rgb), 0.55),
          inset 0px 0px 3px 0px rgba(color-var(info, base-rgb), 0.55);
      }
    }
  }

  &.-flat.-alert {
    & > .border { border-color: color-var(alert); }
    & > .icon { @include icon-color(alert); }

    @include responsive (tablet, desktop) {
      &:hover {
        border: 1px solid color-var(alert);
        box-shadow:
          0px 0px 3px 0px rgba(color-var(alert, base-rgb), 0.55),
          inset 0px 0px 3px 0px rgba(color-var(alert, base-rgb), 0.55);
      }
    }
  }

  &.-flat.-white {
    border: 1px solid rgba(#FFF, .3);

    & > .border { border-color: rgba(#FFF, .3); }
    & > .icon { @include icon-color(rgba(#FFF, .5)); }
    & > .text { color: rgba(#FFF, .5); }
    & > .loader svg path { fill: rgba(#FFF, .5); }

    @include responsive (tablet, desktop) {
      &:hover {
        border: 1px solid rbga(#FFF, .5);
        box-shadow:
          0px 0px 3px 0px rgba(#FFF, 0.55),
          inset 0px 0px 3px 0px rgba(#FFF, 0.55);

        & > .icon { @include icon-color(rgba(#FFF, .8)); }
        & > .text { color: rgba(#FFF, .8); }
        & > .loader svg path { fill: rgba(#FFF, .8); }
      }
    }
  }

  &.-flat-blur.-white {
    & > .icon { @include icon-color(rgba(#FFF, .8)); }
    & > .border { border-color: rgba(#FFF, .8); }

    @include responsive (tablet, desktop) {
      &:hover {
        border: 1px solid #FFF;
        background-color: color-var(text, base-20);
        box-shadow:
          0px 0px 3px 0px #FFF,
          inset 0px 0px 3px 0px #FFF;

        & > .icon { @include icon-color(#FFF); }
      }
    }
  }

  &.-flat.-disabled {
    border: 1px solid color-var(text, base-10);
    background: transparent;

    & > .icon { @include icon-color(text, base-10); filter: unset; }
    & > .text { color: color-var(text, base-30); }

    @include responsive (tablet, desktop) {
      &:hover {
        border: 1px solid color-var(text, base-10);
        box-shadow: none;

        & > .icon { @include icon-color(text, base-10); }
        & > .text { color: color-var(text, base-30); }
      }
    }
  }

  &.-full-width {
    width: 100%;
    border-radius: 0;
    &, &:hover { box-shadow: none; }
    & > .border { display: none; }
  }

  &.-ghost {
    background-color: transparent;
    border: none;
    padding: 0;

    &, &:hover { box-shadow: none; }
    &::after, & > .border { display: none; }
    & > .text { color: var(--base-text-color); }
    & > .icon { @include icon-color(var(--base-text-color)); }
    & > .loader svg { filter: unset; path { fill: var(--base-text-color); } }
  }

  &.-disabled {
    cursor: default;
    background: color-var(disabled);

    &, &:hover { box-shadow: none;}
    &::after { opacity: 0; }

    & > .icon {
      @include icon-color(text, base-20);
      filter: unset;
    }

    &.-alternative-disabled {
      background: rgba(#FFF, .1);
      & > .icon { @include icon-color(rgba(#FFF, .3)); }
      & > .text { color: rgba(#FFF, .3); }
    }

    & > .text {
      color: color-var(text, base-20);
    }
  }

  &.-dashed,
  &.-dashed:hover { border-style: dashed; }

  &.-flat-border:not(.-disabled) {
    border: 1px solid color-var(text, base-10);

    &:hover {
      border: 1px solid color-var();
      box-shadow:
        0px 0px 3px 0px rgba(color-var(primary, base-rgb), 0.55),
        inset 0px 0px 3px 0px rgba(color-var(primary, base-rgb), 0.55);
    }

    &.-error:hover {
      border: 1px solid color-var(negative);
      box-shadow:
        0px 0px 3px 0px rgba(color-var(negative, base-rgb), 0.55),
        inset 0px 0px 3px 0px rgba(color-var(negative, base-rgb), 0.55);
    }
  }

  &.-shadow {
    box-shadow: 0px 9px 13px -6px color-var(text, base-50);

    &:hover { box-shadow: 0px 4px 22px -2px color-var(text, base-60); }

    &::after { background: color-var(text, base-50); }
  }

  &.-loading {
    pointer-events: none;
    & > .text, .icon { visibility: hidden; }
  }

  &.-beta > .text {
    &:after {
      display: inline-block;
      align-self: center;
      content: "Beta";
      background: color-var(negative);
      color: white;
      font-size: 9px;
      font-family: Ubuntu;
      font-weight: 500;
      text-transform: uppercase;
      padding: 3px 5px 3px;
      margin-left: 5px;
      border-radius: 20px;
      transform: translateY(-1px);
      box-shadow: 0 1px 4px 1px rgba($negative-color, .3);
    }
  }

  &.-show-notifications-on-hover > .badge-container > .badge > .notification {
    opacity: 0;
    transition: all .3s ease-in-out;
    width: 9px;
  }

  &.-show-notifications-on-hover:hover > .badge-container > .badge > .notification {
    opacity: 1;
    width: 13px;
  }

  &.-show-notifications-on-hover:hover > .-three-digit > .badge > .notification {
    opacity: 1;
    width: 20px;
  }

  // loading transitions
  & > .loading-enter-active { transition: opacity .1s .1s; }

  & > .loading-leave-active { transition: opacity .1s; }

  & > .loading-enter,
  & > .loading-leave-to { opacity: 0; }

  & > .c-loader { mix-blend-mode: screen; }

  // !! This mixin does not exist in this version
  @include ie {
    &::before { background: none !important; }
  }
}
</style>
