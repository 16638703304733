<template>
  <c-form-builder-modal
    label-left
    class="document-form-modal"
    name="document-modal"
    alternative-header
    header-icon="user-profile-8"
    v-model="data"
    has-files
    :header-theme="theme"
    :title="value.title"
    :files="value.files"
    :schema="schema"
    :fields-options="options"
    :is-disabled="disabled || loading"
    :is-loading="loading"
    :has-top-shadow="false"
    :has-bottom-shadow="false"
    :required-fields="value.requiredFields"
    :read-only="readOnly"
    :show-edit-button="edit"
    @submit="onSubmit"
    @edit="$emit('edit')"
    @close="$emit('close')"
  >
    <div class="upload" footer>
      <c-title v-if="hasFields" dark-text class="title">
        Anexos
      </c-title>

      <span
        v-if="value.text"
        class="text"
        v-html="value.text"
      />

      <document-upload
        ref="upload"
        :value="files"
        :add="showAddButton"
        :disabled="disabled"
        :required="value.requiredFiles"
        :slots="value.filesSlots"
        :limit="value.filesLimit"
        :style="{ marginTop: hasFields ? '0px' : '20px' }"
        @add="onAddFile"
        @remove="onRemoveFile"
      />
    </div>
  </c-form-builder-modal>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
import DocumentUpload from './DocumentUpload'

export default {
  name: 'DocumentModal',

  mixins: [ MediaQuery ],

  components: {
    DocumentUpload
  },

  props: {
    value: {
      type: Object,
      default: () => ({
        title: '',
        text: '',
        data: {},
        files: [],
        filesPlaceholder: [],
        filesLimit: 2,
        filesSlots: true,
        requiredFiles: false,
        requiredFields: []
      })
    },

    schema: {
      type: Object,
      default: () => ({})
    },

    options: {
      type: Object,
      default: () => ({})
    },

    title: {
      type: String,
      default: 'Documento'
    },

    text: {
      type: String,
      default: undefined
    },

    loading: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    theme: {
      type: String,
      default: 'primary' // primary | accent
    },

    edit: Boolean,

    readOnly: Boolean,
  },

  created () {
    const filesSize = this.value.filesSlots
      ? Math.max(this.value.files.length, this.value.filesPlaceholder.length, 1)
      : Math.max(this.value.files.length, 1)

    for (let i = 0; i < filesSize; i++) {
      const file = this.value.files[i]
      const placeholder = (this.value.filesPlaceholder || [])[i]

      this.files.push({
        ...file,
        placeholder
      })
    }

    this.data = { ...this.value.data }
  },

  data: () => ({
    files: [],
    data: {},
    replaced: [],
  }),

  computed: {
    hasFiles () {
      return this.files.every(({ name }) => !!name)
    },

    hasFields () {
      return Object.values(this.schema)
        .some(field => !field.hide)
    },

    showAddButton () {
      const files = this.files.filter(file => !!file.name)

      return !this.value.filesSlots && files.length > 0
    },
  },

  methods: {
    onClose () {
      this.$emit('close')
    },

    onAddFile ({ file, index }) {
      const removed = this.files.splice(index, 1, file)
      const replaced = removed.filter(({ id }) => !!id)

      this.replaced = [ ...this.replaced, ...replaced ]
    },

    onRemoveFile (index) {
      if (this.files[index]?.id)
        this.replaced.push(this.files[index])

      if (!this.value.filesSlots && this.files.length > 1)
        return this.files.splice(index, 1)

      this.files = this.files.map((file, i) =>
        index === i ? { placeholder: file.placeholder } : file
      )
    },

    onSubmit (data) {
      if (!this.$refs.upload.validate()) return

      this.$emit('submit', { data, replaced: this.replaced, files: this.files })
    },
  }
}
</script>

<style lang="scss">
.document-form-modal {
  & > .wrapper .modal {
    & > .header {
      display: flex;
      align-items: center;
    }
    & > .content {
      .upload {
        & > .title {
          margin-bottom: 20px;
        }

        & > .text {
          font-size: 14px;
          line-height: 19px;
          color: #121E48;
          display: block;
          margin-bottom: 20px;
          font-weight: 500;
          & > b { font-weight: 600; }
        }
      }

      & > .body {
        & > .form {
          & > .c-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          & > .c-form-builder {
            & > .actions { display: none }
          }
        }
      }

      & > .actions {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        & > .c-button { width: 180px; margin: 0 10px; }
      }
    }
  }

  @include responsive (xs-mobile, mobile) {
    & > .wrapper { overflow: hidden; }

    & > .wrapper > .modal {
      & > .header { padding: 10px; }

      & > .content {
        flex: 1;
        padding: { left: 0; right: 0; bottom: 0; }

        & > .submit {
          width: 100%;
          border-radius: 0 !important;
          min-height: 60px !important;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  @include responsive (tablet, desktop) {
    & > .wrapper > .modal {
      min-width: 960px;

      & > .content {
        & > .body {
          & > .form {
            & > .c-form-builder {
              & > .c-shadowed {
                overflow: initial;
                & > .wrapper {
                  overflow: initial;
                  max-width: initial;

                  & > .form-fields { padding: 0; }
                }
              }
            }
          }

          & > .actions {
            display: flex;
            margin-top: 40px;
            justify-content: flex-end;

            .c-button {
              margin: 0 10px; width: 180px;

              &.-error > .text { color: #FF7EB3; }
            }
          }
        }

        .upload { max-width: 590px; margin-left: -20px; }
      }
    }
  }
}
</style>
