<template>
  <div class="c-condensed-names">
    <c-typo v-bind="$attrs">
      {{ displayedNames }}{{ comma }}
    </c-typo>

    <c-typo
      v-if="remainingCount > 0"
      v-tooltip.dark="{ value: remainingNames }"
      :class="classes"
      opacity="base-50"
      weight="semibold"
    >
      +{{ remainingCount }}
    </c-typo>
  </div>
</template>

<script>
export default {
  name: 'CCondensedNames',

  props: {
    names: {
      type: Array,
      required: true
    },

    splitValue: {
      type: Number,
      required: true
    },

    underlineDashed: {
      type: Boolean,
      default: true
    },

    commaAfterFirstName: {
      type: Boolean,
      default: false
    },

  },

  computed: {
    classes () {
      return {
        'underline-dashed': this.underlineDashed
      }
    },

    comma () {
      return this.splitValue === 1
        && this.remainingCount > 1 && this.commaAfterFirstName ? ',' : ''
    },

    displayedNames () {
      return this.names.slice(0, this.splitValue).join(', ')
    },
    splitedNames () {
      return this.names.slice(this.splitValue)
    },
    remainingNames () {
      return this.splitedNames.join(', ')
    },
    remainingCount () {
      return this.splitedNames.length
    }
  }
}
</script>

<style lang="scss">
.c-condensed-names {
  & > .c-typo.underline-dashed {
    cursor: default;
    position: relative;
  }

& > .c-typo.underline-dashed::after {
  content: '';
  position: absolute;
  bottom: 0.9px;
  left: 0;
  right: 0;
  border-bottom: 1px dashed color-var(text, base-50);
}
}
</style>
