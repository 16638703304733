<template>
  <div :class="computedClass" :style="computedStyle" ref="switch">
    <input
      :id="id"
      type="checkbox"
      class="input-switch"
      :checked="modelValue"
      :disabled="isDisabled"
      @change="handleChange"
    />

    <label class="switch" :for="id">
      <span
        :class="[ 'switch-label-default', !isChecked ? '-checked' : null ]"
        ref="labelLeft"
      >
        <slot name="labelLeft">Off</slot>
      </span>

      <span
        :class="[ 'switch-label-checked', isChecked ? '-checked' : null ]"
        ref="labelRight"
      >
        <slot name="labelRight">On</slot>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'CSwitchText',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'default',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    switchLeft: '0px',
    switchWidth: '0px',
    isPainted: false,
  }),
  watch: {
    modelValue (newVal) {
      this.updateSwitchSide(newVal)
    },
  },
  computed: {
    computedClass () {
      return [
        'c-switch-text',
        {
          '-size-small': this.size === 'small',
          '-is-painted': this.isPainted,
          '-is-disabled': this.isDisabled,
        },
      ]
    },
    computedStyle () {
      return {
        '--c-switch-text-left': this.switchLeft,
        '--c-switch-text-width': this.switchWidth,
      }
    },
    isChecked () {
      return this.modelValue
    },
  },
  methods: {
    handleChange () {
      if (this.isDisabled) return

      this.$emit('change', !this.modelValue)
    },
    updateSwitchSide (isRight = false) {
      this.setSwitchSide(isRight)
    },
    setSwitchSide (isRight = false) {
      const side = isRight ? 'right' : 'left'
      const { labelLeft, labelRight } = this.$refs

      if (labelLeft && labelRight) {
        const rectLeft = labelLeft.getBoundingClientRect()
        const rectRight = labelRight.getBoundingClientRect()

        const widthLeft = rectLeft.width
        const widthRight = rectRight.width

        const [ leftWidth, rightWidth ] =
          side === 'left' ? [ 0, widthLeft ] : [ widthLeft, widthRight ]
        this.setCSSVars(leftWidth, rightWidth)
      }
    },
    setCSSVars (left, width) {
      this.switchLeft = `${left}px`
      this.switchWidth = `${width}px`
    },
  },
  mounted () {
    setTimeout(() => this.updateSwitchSide(this.modelValue), 300)
    setTimeout(() => {
      this.isPainted = true
    }, 500)
  },
}
</script>

<style lang="scss">
.c-switch-text {
  --c-switch-text-padding: 12px 20px;
  --c-switch-text-font-size: 12px;
  position: relative;

  & > .input-switch {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;

    &:not(:checked) + .switch {
      & > .switch-label-default {
        color: #ffffff;
      }
    }

    &:checked + .switch {
      & > .switch-label-checked {
        color: #ffffff;
      }
    }
  }

  &.-is-painted {
    & > .switch {
      &:after {
        transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
      }
    }
  }

  &.-is-disabled {
    & > .switch {
      cursor: not-allowed;
      background: rgba(map-get($disabled-color-map, baseDark), 0.05);

      &.switch {
        & > .switch-label-default,
        & > .switch-label-checked {
          color: rgba(map-get($disabled-color-map, baseDark), 0.3);
        }
      }

      &:after {
        background: rgba(map-get($disabled-color-map, baseDark), 0.1);
      }
    }
  }

  &:not(.-is-painted) > .switch {
    & > [class*="switch-label"].-checked {
      background-color: var(--color-text-base);
    }
  }

  & > .switch {
    display: inline-flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    color: var(--color-text-base);
    background-color: color-var(disabled);
    border-radius: 40px;
    cursor: pointer;
    outline: 0;

    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: var(--c-switch-text-left, 0px);
      width: var(--c-switch-text-width, 0px);
      height: 100%;
      border-radius: 40px;
      background-color: var(--color-text-base);
    }

    & > [class*="switch-label"] {
      position: relative;
      z-index: 10;
      display: inline-flex;
      justify-content: center;
      padding: var(--c-switch-text-padding);
      color: var(--color-text-base-60);
      font-family: $title-font-family;
      font-size: var(--c-switch-text-font-size);
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
      border-radius: 40px;
      overflow: hidden;
      transition: color 0s linear 0.15s;

      & > span {
        display: inline-block;
        margin-left: 4px;
        color: var(--color-negative-base);
      }
    }
  }

  // Variants
  &.-size-small {
    --c-switch-text-padding: 8px 20px;
    --c-switch-text-font-size: 10px;
  }
}
</style>
