import * as services from '@services/document'
import * as types from '@types'
import defaults from './defaults'

const apiUrl = import.meta.env.VITE_EMPLOYEE_REST_API

export default {
  state: {
    documents: { ...defaults },
    customDocuments: {}
  },

  getters: {
    [types.DOCUMENT_GET_DOCUMENTS]: state => ({
      ...state.documents,
      ...state.customDocuments,
    })
  },

  mutations: {
    [types.DOCUMENT_RESET]: (state) => {
      state.documents = { ...defaults }
      state.customDocuments = {}
    },

    [types.DOCUMENT_SET_STATUS]: (state, { type, status }) => {
      const document = state.documents[type] || state.customDocuments[type]

      if (!document) return

      document.status = status
    },

    [types.DOCUMENT_SET_FILES]: (state, { type, files }) => {
      const document = state.documents[type] || state.customDocuments[type]

      if (!document) return

      document.files = files?.map(file => ({ ...file, progress: 0, uploading: false })) || []
    },

    [types.DOCUMENT_CUSTOM_FIELDS]: (state, customFields) => {
      Object.entries(customFields)
        .forEach(([ type, fields ]) => {
          const isCustom = !!state.customDocuments[type]
          const document = state.documents[type] || state.customDocuments[type]

          if (!document) return

          document.customFields = { ...fields }

          if (isCustom) {
            document.requiredFiles = Object.values(fields)
              .findIndex(field => field.type === 'file' && field.required) >= 0
          }
        })
    },

    [types.DOCUMENT_CUSTOM_DOCUMENTS]: (state, customDocuments) => {
      state.customDocuments = customDocuments
        .reduce((acc, { id, ...document }) => ({
          ...acc,
          [id]: {
            id,
            title: document.name,
            status: state.customDocuments[id]?.status || 'default',
            files: document.files,
            filesPlaceholder: [],
            filesLimit: 4,
            filesSlots: false,
            customFields: {},
            data: {},
            requiredFiles: false,
            requiredFields: []
          }
        }), {})
    },

    [types.DOCUMENT_SET_RG]: (state, data) => {
      const doc = state.documents.rg.data

      state.documents.rg = {
        ...state.documents.rg,
        id: data.id || doc.id || '',
        data: {
          ...doc,
          number: data.number || doc.number || '',
          emission_date: data.emission_date || doc.emission_date || '',
          issuing_agency: data.issuing_agency || doc.issuing_agency || '',
          issuing_state: data.issuing_state || doc.issuing_state
        },
        files: [ ...data.files || [] ]
      }
    },

    [types.DOCUMENT_SET_CNH]: (state, data) => {
      const doc = state.documents.driver_license.data

      state.documents.driver_license = {
        ...state.documents.driver_license,
        id: data.id || doc.id || '',
        data: {
          ...doc,
          number: data.number || doc.number || '',
          emission_date: data.emission_date || doc.emission_date || '',
          validate_date: data.validate_date || doc.validate_date || '',
          category: data.category || doc.category || ''
        },
        files: [ ...data.files || [] ]
      }
    },

    [types.DOCUMENT_SET_CPF]: (state, data) => {
      const doc = state.documents.cpf.data

      state.documents.cpf = {
        ...state.documents.cpf,
        id: data.id || doc.id || '',
        data: {
          ...doc,
          cpf: data.cpf || doc.cpf || ''
        },
        files: [ ...data.files || [] ]
      }
    },

    [types.DOCUMENT_SET_RESERVIST]: (state, data) => {
      const doc = state.documents.reservist.data

      state.documents.reservist = {
        ...state.documents.reservist,
        id: data.id || doc.id || '',
        data: {
          ...doc,
          reservist: data.reservist || doc.reservist || '',
          ra_number: data.ra_number || doc.ra_number || '',
          series: data.series || doc.series || '',
        },
        files: [ ...data.files || [] ]
      }
    },

    [types.DOCUMENT_SET_ELECTORAL]: (state, data) => {
      const doc = state.documents.electoral_card.data

      state.documents.electoral_card = {
        ...state.documents.electoral_card,
        id: data.id || doc.id || '',
        data: {
          ...doc,
          number: data.number || doc.number || '',
          electoral_ward: data.electoral_ward || doc.electoral_ward || '',
          section: data.section || doc.section || '',
          state: data.state || doc.state,
          city: data.city || doc.city
        },
        files: [ ...data.files || [] ]
      }
    },

    [types.DOCUMENT_SET_CTPS]: (state, data) => {
      const doc = state.documents.ctps.data

      state.documents.ctps = {
        ...state.documents.ctps,
        id: data.id || doc.id || '',
        data: {
          ...doc,
          number: data.number || doc.number || '',
          serial_number: data.serial_number || doc.serial_number || '',
          emission_date: data.emission_date || doc.emission_date || '',
          issuing_state: data.issuing_state || doc.issuing_state,
          pis: data.pis || doc.pis || '',
        },
        files: [ ...data.files || [] ]
      }
    },

    [types.DOCUMENT_SET_CUSTOM]: (state, data) => {
      const document = state.customDocuments[data.id]

      if (!document) return

      document.data = data
    },

    [types.DOCUMENT_REQUIRED_FIELDS]: (state, { requiredFields }) => {
      Object.entries(requiredFields)
        .forEach(([ type, fields ]) => {
          const document = state.documents[type] || state.customDocuments[type]

          if (!document) return

          document.requiredFiles = fields.includes('attachment')
          document.requiredFields = [
            ...document.requiredFields,
            ...fields.filter(key => key !== 'attachment')
          ]
        })
    }
  },

  actions: {
    [types.DOCUMENT_GET_DOCUMENTS]: async ({ commit, dispatch, getters }) => {
      const token = getters.getToken
      const employeeId = getters.getEmployeeUuid
      const companyId = getters.getCompanyUuid
      const params = { apiUrl, employeeId, companyId, token }

      const [ err, data ] = await services.getDocuments(params)

      commit(types.DOCUMENT_SET_RG, data.rg)
      commit(types.DOCUMENT_SET_CNH, data.driver_license)
      commit(types.DOCUMENT_SET_CPF, data.cpf)
      commit(types.DOCUMENT_SET_ELECTORAL, data.electoral_card)
      commit(types.DOCUMENT_SET_CTPS, data.ctps)
      commit(types.DOCUMENT_SET_RESERVIST, data.reservist)

      await dispatch(types.DOCUMENT_REQUIRED_FIELDS)

      return [ err, data ]
    },

    [types.DOCUMENT_CUSTOM_FIELDS]: async ({ commit, getters }) => {
      const token = getters.getToken
      const employeeId = getters.getEmployeeUuid
      const companyId = getters.getCompanyUuid
      const params = { apiUrl, employeeId, companyId, token }

      const [ err, customFields ] = await services.getCustomFields(params)

      if (!err) commit(types.DOCUMENT_CUSTOM_FIELDS, customFields)

      return [ err, customFields ]
    },

    [types.DOCUMENT_CUSTOM_DOCUMENTS]: async ({ commit, getters }) => {
      const token = getters.getToken
      const employeeId = getters.getEmployeeUuid
      const companyId = getters.getCompanyUuid
      const params = { apiUrl, employeeId, companyId, token }

      const [ err, customDocuments ] = await services.getCustomDocuments(params)

      if (!err) commit(types.DOCUMENT_CUSTOM_DOCUMENTS, customDocuments)

      return [ err, customDocuments ]
    },

    [types.DOCUMENT_REQUIRED_FIELDS]: async ({ commit, getters }) => {
      const { requiredFields = [] } = getters || {}

      commit(types.DOCUMENT_REQUIRED_FIELDS, { requiredFields })
    },

    [types.DOCUMENT_SET_RG]: async ({ getters }, variables) => {
      const token = getters.getToken
      const employeeId = getters.getEmployeeUuid
      const companyId = getters.getCompanyUuid
      const params = { apiUrl, employeeId, companyId, token }

      const [ err, data, endpoint ] = await services.setRg(variables, params)

      return [ err, data, endpoint ]
    },

    [types.DOCUMENT_SET_RESERVIST]: async ({ getters }, variables) => {
      const token = getters.getToken
      const employeeId = getters.getEmployeeUuid
      const companyId = getters.getCompanyUuid
      const params = { apiUrl, employeeId, companyId, token }

      const [ err, data, endpoint ] = await services.setReservist(variables, params)

      return [ err, data, endpoint ]
    },

    [types.DOCUMENT_SET_CNH]: async ({ getters }, variables) => {
      const token = getters.getToken
      const employeeId = getters.getEmployeeUuid
      const companyId = getters.getCompanyUuid
      const params = { apiUrl, employeeId, companyId, token }

      const [ err, data, endpoint ] = await services.setDriverLicense(variables, params)

      return [ err, data, endpoint ]
    },

    [types.DOCUMENT_SET_CPF]: async ({ getters }, variables) => {
      const token = getters.getToken
      const employeeId = getters.getEmployeeUuid
      const companyId = getters.getCompanyUuid
      const params = { apiUrl, employeeId, companyId, token }

      const [ err, data, endpoint ] = await services.setCpf(variables, params)

      return [ err, data, endpoint ]
    },

    [types.DOCUMENT_SET_ELECTORAL]: async ({ getters }, variables) => {
      const token = getters.getToken
      const employeeId = getters.getEmployeeUuid
      const companyId = getters.getCompanyUuid
      const params = { apiUrl, employeeId, companyId, token }

      const [ err, data, endpoint ] = await services.setElectoralCard(variables, params)

      return [ err, data, endpoint ]
    },

    [types.DOCUMENT_SET_CTPS]: async ({ getters }, variables) => {
      const token = getters.getToken
      const employeeId = getters.getEmployeeUuid
      const companyId = getters.getCompanyUuid
      const params = { apiUrl, employeeId, companyId, token }

      const [ err, data, endpoint ] = await services.setCtps(variables, params)

      return [ err, data, endpoint ]
    },

    [types.DOCUMENT_SET_CUSTOM]: async ({ getters }, variables) => {
      if (!variables) return []

      const token = getters.getToken
      const employeeId = getters.getEmployeeUuid
      const companyId = getters.getCompanyUuid
      const params = { apiUrl, employeeId, companyId, token }

      const [ err, data, endpoint ] = await services.setCustomDocument(variables, params)

      return [ err, data, endpoint ]
    },

    [types.DOCUMENT_VALIDATE_DOCUMENTS]: async ({ state, commit }, params) => {
      const { validated = [], errors = [] } = params

      let isValid = true

      Object.entries({ ...state.documents, ...state.customDocuments })
        .forEach(([ type ]) => {
          const valid = validated.includes(type)
          const error = errors.includes(type)

          commit(types.DOCUMENT_SET_STATUS, {
            type,
            status: valid ? 'success' : error ? 'error' : 'default'
          })

          if (isValid)
            isValid = valid && !error
        })

      return isValid
    }
  }
}
