import { formatDateUS } from '@convenia/helpers'
import { mapFiles } from './files'
import { masker } from '../../helpers'

export const mapCustomDocument = ({ files, ...data }, params) => ({
  ...data,
  files: mapFiles(files, params)
})

export const documentMappers = {
  rg: ({ files, ...data }, params) => ({
    ...data,
    ...(data.issuing_state_id ? { issuing_state: +data.issuing_state_id } : {}),
    emission_date: formatDateUS(data.emission_date),
    files: mapFiles(files, params)
  }),

  cpf: ({ files, ...data }, params) => ({
    ...data,
    cpf: masker('###.###.###-##', data.cpf),
    files: mapFiles(files, params),
    preventRemove: true
  }),

  ctps: ({ files, ...data }, params) => ({
    ...data,
    ...(data.issuing_state_id ? { issuing_state: +data.issuing_state_id } : {}),
    pis: masker('###.#####.##-#', data.pis),
    emission_date: formatDateUS(data.emission_date),
    files: mapFiles(files, params)
  }),

  electoral_card: ({ files, ...data }, params) => ({
    ...data,
    ...(data.city ? { city: +data.city.id } : {}),
    ...(data.state ? { state: +data.state.id } : {}),
    files: mapFiles(files, params)
  }),

  driver_license: ({ files, ...data }, params) => ({
    ...data,
    emission_date: !Number.isNaN(Date.parse(data.emission_date))
      ? formatDateUS(data.emission_date)
      : '',
    validate_date: !Number.isNaN(Date.parse(data.validate_date))
      ? formatDateUS(data.validate_date)
      : '',
    files: mapFiles(files, params)
  }),

  reservist: ({ files, ...data }, params) => ({
    ...data,
    files: mapFiles(files, params)
  }),

  dismissal: ({ files, ...data }, params) => ({
    ...data,
    files: mapFiles(files, params)
  }),

  other_document: ({ files, ...data }, params) => ({
    ...data,
    files: mapFiles(files, params)
  }),

  files: (files, params) => mapFiles(files, params)
}

export const mapDocuments = (data, params) => ({
  cpf: documentMappers.cpf(data.cpf || {}, params),
  ctps: documentMappers.ctps(data.ctps || {}, params),
  rg: documentMappers.rg(data.rg || {}, params),
  reservist: documentMappers.reservist(data.reservist || {}, params),
  driver_license: documentMappers.driver_license(data.driver_license || {}, params),
  electoral_card: documentMappers.electoral_card(data.electoral_card || {}, params),
  ...(data.dismissal?.id
    ? { dismissal: documentMappers.dismissal(data.dismissal || {}, params) }
    : {}
  ),
})
