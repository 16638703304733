<template>
  <c-card
    class="admission-document-card"
    :green="success"
    :background-color="backgroundColor"
    :no-border="error"
    :class="classes"
    @click="$emit('click', $event)"
  >
    <div class="icon">
      <transition name="stamp">
        <c-flag
          v-if="success"
          success
          class="check"
          icon="done-check-3"
        />
      </transition>

      <c-flag
        :grey="!error && !success"
        :icon="error ? 'question-circle' : icon"
      />
    </div>

    <div class="content">
      <c-typo class="title" type="h2">
        {{ title }}
      </c-typo>

      <c-typo class="message" type="body-2">
        {{ message }}
      </c-typo>
    </div>

    <transition name="fade">
      <div class="footer">
        <div v-if="files" class="attachment">
          <c-icon icon="clip-attachment" size="18" />

          <span class="text">
            {{ files }} Anexo{{ files > 1 ? 's' : '' }}
          </span>
        </div>

        <span class="more">
          <c-typo v-if="isMobile" class="text" type="h4" opacity="base-50">
            Ver Mais
          </c-typo>

          <c-icon
            class="arrow"
            icon="arrow-right"
            :size="isMobile ? 11 : 18"
          />
        </span>
      </div>
    </transition>
  </c-card>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'AdmissionDocumentCard',

  mixins: [ MediaQuery ],

  props: {
    title: {
      type: String,
      default: 'Documento'
    },
    icon: {
      type: String,
      default: 'user-profile-8'
    },
    error: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    files: {
      type: Number,
      default: 0
    },
    message: {
      type: String,
      default: 'Documento obrigatório'
    }
  },

  computed: {
    classes () {
      return {
        '-has-error': this.error
      }
    },

    backgroundColor () {
      if (!this.error)
        return undefined

      const [ r, g, b ] = this.$css.get('--color-negative-base-rgb').split(', ')

      return `rgba(${r}, ${g}, ${b}, 0.2)`
    }
  }

}
</script>

<style lang="scss">
.admission-document-card {
  padding: 15px 15px 20px 20px;
  display: flex;
  align-items: center;

  & > .icon {
    flex-shrink: 0;
    margin-right: 10px;
    position: relative;
    & > .check {
      position: absolute;
      z-index: var(--z-index-2);
    }
  }

  & > .content {
    flex: 1;
    min-width: 0;

    & > .title {
      @include truncate(100%);
    }

    & > .message {
      display: block;
      height: 0px;
      opacity: 0;
      visibility: hidden;
      transition: .3s ease-out;
      color: color-var(negative);
    }
  }

  & > .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .attachment {
      background: color-var(text, base-05);
      border-radius: 20px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      height: 25px;

      & > .text {
        padding: 0 5px;
        @include typo(h5, base-60)
      }
    }

    & > .more {
      display: flex;
      align-items: center;
      & > .text { padding: 0 5px; }
    }

    .c-icon { @include icon-color(text, base-30); }
  }

  &.-has-error {
    & > .icon > .c-flag {
      background: rgba(color-var(negative, base-rgb), .1);

      & > .c-icon {
        @include icon-color(negative);
        filter: none;
      }
    }

    & > .content {
      & > .title { color: color-var(negative); }

      & > .message {
        height: 15px;
        opacity: 1;
        visibility: visible;
      }
    }

    & > .footer > .more {
      & > .text { color: color-var(negative); }
      & > .arrow {
        @include icon-color(negative);
        opacity: .5;
      }
    }
  }

  @include responsive (xs-mobile, mobile) {
    flex-flow: row wrap;
    max-height: 120px;
    & > .footer {
      display: flex;
      margin-top: 20px;
      flex: 100%;
      align-items: flex-end;
      min-height: 25px;
      & > .more {
        flex: 1;
        justify-content: flex-end;
      }
    }
  }

  @include responsive (tablet, desktop) {
    cursor: pointer;
    padding: 30px;
    max-height: 100px;
    & > .content {
      & > .icon { margin-right: 20px; }
    }
    & > .footer {
      & > .attachment, & > .more { margin-left: 10px }
    }
  }
}

.stamp-enter-active, .stamp-leave-active {
  transition: opacity .3s, transform .3s;
}
.stamp-enter, .stamp-leave-to {
  opacity: 0;
  transform: scale(1.5);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
