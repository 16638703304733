export const PERSONAL_DOCUMENTS_AREA = {
  LABEL: 'documents.personal_documents',
  CNH: '7f0e3b95-bab2-4f7e-b4fb-34a4d3a92794',
  CTPS: '1dd72199-68e9-4a64-b57b-3844a8fb9488',
  CPF: 'eb8e3dcf-6d45-4554-8f1e-cb721dbd05bc',
  RESERVIST: '8459053d-7a96-461e-a28d-b72d90f7e95e',
  RG: '9831bcd4-21c7-4d45-a8a4-b95435e61511',
  ELECTORAL: 'dfa25607-f8f5-4d5d-bf64-f1f8563dfcb7',
}

export const INVALID_CPF_ERROR = 'cpf: The cpf has already been taken.'

export const BRAZILLIAN_IDS = [ 9, 10 ]
