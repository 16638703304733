<template>
  <div class="document-file-upload">
    <c-add
      v-if="canAddFile"
      thin
      text="Procure um arquivo"
      @add="onSelectFile(value.length)"
    />

    <div class="files">
      <transition name="fade">
        <form-field-validation
          v-if="errorMessage"
          class="error"
          :message="errorMessage"
        />
      </transition>

      <document-file
        v-for="(file, index) in value"
        ref="files"
        :key="index"
        :data-testid="`file-${index}`"
        :value="file"
        :extensions="extensions"
        :placeholder="file.placeholder"
        :disabled="disabled"
        @click="onPreviewOpen(index)"
        @remove="onRemoveFile(index)"
        @change="onSelectFile(index)"
        @drag="files => onDragFiles(index, files)"
      />
    </div>

    <c-preview
      :is-opened="showPreview"
      :images="images"
      :remove="false"
      :current-image="current"
      @next="onPreviewNext"
      @previous="onPreviewPrevious"
      @close="onPreviewClose"
      @download="onDownloadFile"
      @click.native.prevent
    />

    <input
      ref="input"
      class="input"
      type="file"
      :accept="extensions.map(ext => '.' + ext)"
      @change.prevent="onAddFiles"
    >
  </div>
</template>

<script>
import FormFieldValidation from '@convenia/components/CFormBuilder/fragments/FormFieldValidation'
import DocumentFile from './DocumentFile'

export default {
  name: 'DocumentUpload',

  components: {
    DocumentFile, FormFieldValidation
  },

  data: () => ({
    showPreview: false,
    active: null,
    current: 0,
  }),

  props: {
    value: {
      type: Array,
      default: () => ([])
    },

    extensions: {
      type: Array,
      default: () => ([ 'pdf', 'png', 'jpg', 'jpeg' ])
    },

    disabled: {
      type: Boolean,
      default: false
    },

    required: {
      type: Boolean,
      default: false,
    },

    slots: {
      type: Boolean,
      default: false,
    },

    limit: {
      type: Number,
      default: 4
    }
  },

  computed: {
    selected () {
      if (!this.value[this.active]) return {}

      return this.value[this.active]
    },

    images () {
      return this.value
        .filter(({ name, preview }) => preview && this.isImage(name))
        .map(file => ({
          ...file,
          src: file.preview,
          label: file.name
        }))
    },

    canAddFile () {
      if (this.slots) return false

      return !this.value.some(file => !file?.name)
    },

    errorMessage () {
      if (this.value.length > this.limit)
        return `Atingido limite de ${this.limit} ${this.limit === 1 ? 'item' : 'itens'}`

      return false
    }
  },

  methods: {
    validate () {
      if (this.errorMessage) return false

      if (!this.required) return true

      return this.$refs.files.every(
        ({ validate }) => validate()
      )
    },

    getExtension (filename) {
      const index = filename.lastIndexOf('.')

      return (index < 1) ? '' : filename.substr(index + 1).toLowerCase()
    },

    isImage (filename) {
      const ext = this.getExtension(filename)

      return [ 'jpg', 'jpeg', 'gif', 'bmp', 'png', 'pdf' ].includes(ext)
    },

    onRemoveFile (index) {
      this.$emit('remove', index)
    },

    onSelectFile (index) {
      this.active = index
      this.$refs['input'].click()
    },

    onDragFiles (index, files) {
      if (!files.length) return

      const file = files[0]
      this.active = index
      this.processFile(file)
    },

    onAddFiles ({ target }) {
      if (!target.files.length) return

      const file = target.files[0]
      this.processFile(file)
      this.$refs.input.value = null
    },

    onPreviewOpen (index) {
      this.active = index
      this.current = this.images
        .findIndex(({ name }) => name === this.selected.name)
      this.showPreview = true
    },

    onPreviewClose () {
      this.current = 0
      this.showPreview = false
    },

    onPreviewNext () {
      this.current += 1
    },

    onPreviewPrevious () {
      this.current -= 1
    },

    async onDownloadFile () {
      const currentFile = this.images[this.current] || {}
      const url = await this.resolve(currentFile)
      url && this.open({ ...currentFile, url })
    },

    async resolve ({ url }) {
      try {
        return typeof url === 'function'
          ? await url() : url
      } catch (e) { this.$emit('file-error') }
    },

    async open ({ openMethod, ...file }) {
      try {
        openMethod
          ? openMethod(file)
          : window.open(file.url, '_blank')
        this.$emit('download', file)
      } catch (e) { this.$emit('file-error') }
    },

    processFile (file) {
      if (!this.extensions.includes(this.getExtension(file.name)))
        return

      const defaults = {
        id: null,
        progress: 0,
        uploading: false,
        preview: null,
        data: file,
        name: file.name,
        size: file.size,
        error: ''
      }

      if (![ 'image/jpg', 'image/jpeg', 'image/png', 'application/pdf' ].includes(file.type))
        return this.$emit('add', {
          index: this.active,
          file: {
            ...this.selected,
            ...defaults
          }
        })

      const reader = new FileReader()

      reader.onloadend = () =>
        this.$emit('add', {
          index: this.active,
          file: {
            ...this.selected,
            ...defaults,
            preview: reader.result
          }
        })

      reader.readAsDataURL(file)
    }
  }
}
</script>

<style lang="scss">
.document-file-upload {
  & > .c-add.-thin {
    margin: 0 0 10px 0;
  }

  & > .files {
    & > .error {
      display: flex;
      justify-content: center;
      margin: 10px 0 15px;
    }

    & > .document-file {
      margin-bottom: 10px;
    }
  }

  & > .input { display: none; }

  & > .c-preview {
    & > .content {
      & > .label {
        width: 75%;
        left: 50%;
        transform: translate(-50%, 30px);
        text-align: center;
      }
    }
  }
}
</style>
