import { PERSONAL_DOCUMENTS_AREA } from '../consts'

export const mapCustomFields = (data) => {
  const rg = data.find(({ id }) => id === PERSONAL_DOCUMENTS_AREA.RG)
  const driver_license = data.find(({ id }) => id === PERSONAL_DOCUMENTS_AREA.CNH)
  const cpf = data.find(({ id }) => id === PERSONAL_DOCUMENTS_AREA.CPF)
  const reservist = data.find(({ id }) => id === PERSONAL_DOCUMENTS_AREA.RESERVIST)
  const electoral_card = data.find(({ id }) => id === PERSONAL_DOCUMENTS_AREA.ELECTORAL)
  const ctps = data.find(({ id }) => id === PERSONAL_DOCUMENTS_AREA.CTPS)

  const customDocuments = data
    .filter(({ id }) => !Object.values(PERSONAL_DOCUMENTS_AREA).includes(id))
    .reduce((acc, { id, schema }) => ({ ...acc, [id]: schema }), {})

  return {
    rg: rg?.schema || {},
    driver_license: driver_license?.schema || {},
    cpf: cpf?.schema || {},
    reservist: reservist?.schema || {},
    electoral_card: electoral_card?.schema || {},
    ctps: ctps?.schema || {},
    ...customDocuments
  }
}
