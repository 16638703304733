<template>
  <div class="admission-documents-step">
    <c-title dark-text>
      Documentos
    </c-title>

    <div class="documents">
      <admission-document-card
        v-for="(document, key) in documents"
        :key="`document-card-${document.id || key}`"
        :title="document.title"
        :files="(document.files || []).length"
        :error="document.status === 'error'"
        :success="document.status === 'success'"
        @click="onEdit(key, document)"
      />
    </div>

    <document-form-modal
      v-if="isEditing"
      :value="value"
      :schema="formFields"
      :loading="isSubmitting"
      :options="options"
      @close="onClose"
      @submit="onSubmit"
    />

    <delete-modal
      v-if="showRemoveFileModal"
      title="Deseja prosseguir?"
      message="Ao prosseguir os arquivos apagados serão permanentemente excluídos,
        esta ação não pode ser desfeita."
      button-message="Excluir"
      cancel-message="Não, mudei de ideia"
      :is-loading="isSubmitting"
      @cancel="showRemoveFileModal = false"
      @delete="onSubmit(filesFormData)"
    />
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'
import AdmissionDocumentCard from './AdmissionDocumentCard'
import DocumentFormModal from '../fragments/DocumentFormModal'
import schema from '../content/forms'

export const validateDocument = document => {
  let valid = true

  if (document.requiredFiles && !document.files.length)
    valid = false

  if (document.requiredFields?.some(key => !document.data[key]))
    valid = false

  Object.values(document.customFields)
    .forEach(field => {
      if (field.type === 'file') return

      if (field.required && !field.value)
        valid = false
    })

  return valid
}

export default {
  name: 'AdmissionDocumentsStep',

  mixins: [ MediaQuery ],

  components: {
    AdmissionDocumentCard,
    DocumentFormModal,
    DeleteModal: () => import('@convenia/common-organisms/Modals/Delete'),
  },

  computed: {
    isCustomDocument () {
      return Object.keys(this.customDocuments)
        .findIndex(id => id === this.active) >= 0
    },

    formFields () {
      const formSchema = schema(this)
      const fields = formSchema[this.active]
      const customFields = Object.values(this.documents[this.active].customFields)
        .reduce((acc, value) => ({
          ...acc,
          ...(value.type === 'file' ? {} : { [value.id]: value })
        }), {})

      return { ...fields, ...customFields }
    },

    value () {
      const notRequired = {
        ...this.documents[this.active],
        requiredFields: [],
        requiredFiles: false
      }

      if (this.active === 'reservist' && this.isFemale)
        return notRequired

      if (this.active === 'cpf' && this.isForeigner)
        return notRequired

      return this.documents[this.active]
    }
  },

  props: {
    documents: {
      type: Object,
      default: () => ({})
    },

    customDocuments: {
      type: Object,
      default: () => ({})
    },

    options: {
      type: Object,
      default: () => ({})
    },

    isFemale: Boolean,

    isForeigner: Boolean,
  },

  data: () => ({
    isEditing: false,
    isSubmitting: false,
    showRemoveFileModal: false,
    filesFormData: {},
    active: null,
  }),

  methods: {
    onEdit (key, document) {
      this.active = key
      this.selected = document
      this.isEditing = true

      const { state, issuing_state } = this.value.data
      this.getCities(state || issuing_state)

      this.$emit('edit', key)
    },

    validate () {
      return Object.entries(this.documents)
        .reduce((acc, [ type, document ]) => {
          const valid = (type === 'reservist' && this.isFemale)
            || (type === 'cpf' && this.isForeigner)
            || validateDocument(document)

          if (!valid)
            acc.errors.push(type)
          else
            acc.validated.push(type)

          return { ...acc }
        }, { errors: [], validated: [] })
    },

    verify () {
      const { validated = [] } = this.validate() || {}

      const verified = validated.filter(key => {
        const document = this.documents[key]

        if (document.status === 'success')
          return true

        const hasId = !!document?.id

        return hasId
      })

      const errors = Object.keys(this.documents)
        .filter(key => {
          const document = this.documents[key]

          return document.status === 'error'
            && !verified.includes(key)
        })

      return { validated: verified, errors }
    },

    onSubmit ({ data, files, replaced }) {
      const { id } = this.selected || {}

      if (replaced.length > 0 && !this.showRemoveFileModal) {
        this.filesFormData = { data, files, replaced }
        this.showRemoveFileModal = true

        return
      }

      this.showRemoveFileModal = false
      this.isSubmitting = true

      const callback = (err) => {
        this.isSubmitting = false

        if (err) return

        this.onClose()
      }

      const key = this.active
      const initialFiles = this.selected?.files || []
      const addedFiles = files.filter(file =>
        !!file.name && !initialFiles.find(({ id }) => file.id === id)
      )
      const replacedFiles = replaced
      const customFields = Object.keys(this.selected?.customFields || {})
      const attachmentId = customFields
        .reduce((acc, id) => {
          if (this.selected?.customFields[id]?.type !== 'file')
            return acc

          return id
        }, null)

      this.$emit('submit', {
        data,
        id,
        key,
        attachmentId,
        addedFiles,
        files,
        initialFiles,
        replacedFiles,
        customFields,
        callback
      })
    },

    getCities (state) {
      if (!state)
        return

      this.$emit('load-cities', state)
    },

    onClose () {
      this.clear()
    },

    clear () {
      this.active = null
      this.selected = null
      this.isEditing = false
      this.showRemoveFileModal = false
      this.filesFormData = {}
    }
  }
}
</script>

<style lang="scss">
.admission-documents-step {
  position: relative;

  & > .c-title {
    padding-bottom: 20px;
  }

  & > .documents {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;
    row-gap: 20px;
    & > .document-card { min-width: 0; }
  }

  @include responsive (tablet, desktop) {
    & > .documents {
      grid-template-columns: 1fr 1fr;
    }
  }

  @include responsive (xs-mobile, mobile) {
    & > .c-title {
      display: none;
    }

    & > .documents {
      padding: 20px 10px;
      row-gap: 10px;
    }
  }
}
</style>
