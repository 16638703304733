<template>
  <div class="admission-documents-container">
    <c-loader v-if="isLoading" :size="isMobile ? 79 : 99" />

    <admission-document-step
      ref="step"
      v-show="!isLoading"
      :is-female="isFemale"
      :is-foreigner="isForeigner"
      :documents="documents"
      :custom-documents="customDocuments"
      :options="options"
      @submit="onSubmit"
      @edit="onEdit"
      @load-cities="loadCities"
    />
  </div>
</template>

<script>
import { Loadable, MediaQuery } from '@convenia/mixins'
import { AdmissionDocumentStep } from '@convenia/documents-organisms'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { INVALID_CPF_ERROR, SET_ACTIONS, BRAZILLIAN_IDS } from '@content/consts/Documents'
import upload from '@modules/upload'
import * as types from '@types'

export default {
  name: 'DocumentsStepContainer',

  mixins: [ MediaQuery, Loadable() ],

  components: {
    AdmissionDocumentStep,
  },

  computed: {
    ...mapState({
      customDocuments: state => state.document.customDocuments,
      states: state => state.location.states,
      personalInfo: state => state.personalInfo.personal,
      employeeId: state => state.admission.employeeUuid,
    }),

    ...mapGetters({
      documents: types.DOCUMENT_GET_DOCUMENTS,
    }),

    isFemale () {
      const { gender, genderIdentities } = this.personalInfo || {}

      return gender === 2 || genderIdentities === 2
    },

    isForeigner () {
      const { nationalities = [] } = this.personalInfo || {}
      const isNotForeigner = !nationalities?.length
        || nationalities.some(id => BRAZILLIAN_IDS.includes(id))

      return !isNotForeigner
    },

    isCustomDocument () {
      return Object.keys(this.customDocuments)
        .findIndex(id => id === this.active) >= 0
    },

    selected () {
      if (!this.documents[this.active]) return {}

      return this.documents[this.active]
    },

    options () {
      return {
        states: this.states,
        issuing_state: this.states,
        cities: this.cities,
      }
    },
  },

  data: () => ({
    showModal: true,
    cities: [],
    active: null,
  }),

  mounted () {
    this.$root.$on('next', this.onNext)
    this.$root.$on('previous', this.onSave)
  },

  beforeDestroy () {
    this.$root.$off('next', this.onNext)
    this.$root.$off('previous', this.onSave)

    this.resetDocuments()
  },

  methods: {
    ...mapMutations({
      setFiles: types.DOCUMENT_SET_FILES,
      resetDocuments: types.DOCUMENT_RESET,
    }),

    ...mapActions({
      deleteFile: types.ADMISSION_DELETE_FILE,
      getDocuments: types.DOCUMENT_GET_DOCUMENTS,
      getCustomDocuments: types.DOCUMENT_CUSTOM_DOCUMENTS,
      getCustomFields: types.DOCUMENT_CUSTOM_FIELDS,
      getStates: types.LOCATION_STATES,
      getCities: types.LOCATION_CITIES,
      validateDocuments: types.DOCUMENT_VALIDATE_DOCUMENTS,
    }),

    async load () {
      await this.getDocuments()
      await this.getCustomDocuments()
      await this.getCustomFields()
      await this.getStates()

      this.isLoading = false

      await this.verify()

      this.$emit('load')
    },

    async onNext () {
      const { validated, errors } = this.$refs.step?.validate() || {}
      const valid = await this.validateDocuments({ validated, errors })

      if (!valid) return

      this.$emit('next')
    },

    onSave () {
      this.$emit('previous')
    },

    async onEdit (key) {
      this.active = key
    },

    async validate () {
      if (this.isLoading) return false

      const { validated, errors } = this.$refs.step?.validate() || {}

      const valid = await this.validateDocuments({ validated, errors })

      return valid
    },

    async verify () {
      if (this.isLoading) return false

      const { validated, errors } = this.$refs.step?.verify() || {}

      const valid = await this.validateDocuments({ validated, errors })

      this.$emit('verify', valid)

      return valid
    },

    submit: async () => {},

    async onSubmit (event) {
      this.showRemoveFileModal = false

      const {
        id, key, data, attachmentId, customFields,
        addedFiles, replacedFiles, callback
      } = event

      const action = this.isCustomDocument
        ? SET_ACTIONS.custom_document
        : SET_ACTIONS[key]

      const payload = this.isCustomDocument
        ? { id, attachmentId, customFields, ...data }
        : { customFields, ...data, id }

      const [ err, , endpoint ] = await this.$store.dispatch(action, payload)

      if (err) {
        const uniqueCpfErr = Array.isArray(err) && err
          .some(({ message }) => message.includes(INVALID_CPF_ERROR))

        this.$feedback.add({
          type: 'error',
          message: uniqueCpfErr ? 'CPF' : 'Erro',
          highlighted: uniqueCpfErr
            ? 'deve ser único dentro da empresa. Por favor, converse com o RH'
            : 'ao salvar o documento. Tente novamente mais tarde.'
        })

        return callback(err)
      }

      await this.deleteFiles(replacedFiles)
      await this.uploadFiles(addedFiles, endpoint)
      await this.getDocuments()
      await this.getCustomDocuments()
      await this.getCustomFields()
      await this.verify()

      callback()

      this.clear()
    },

    async uploadFiles (files, endpoint) {
      const uploads = await upload({ files, endpoint })

      return Promise.all(uploads.map(({ request }) => request))
    },

    async deleteFiles (files) {
      const { employeeId } = this

      await Promise.all(files.map(
        file => this.deleteFile({ file, employeeId }))
      )
    },

    async loadCities (stateId) {
      if (!stateId) return

      this.cities = await this.getCities(stateId)
    },

    clear () {
      this.active = null
      this.cities = []
    }
  }
}
</script>

<style lang="scss">
.admission-documents-container {
  position: relative;

  & > .c-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }

  & > .admission-documents-step {
    margin-top: 20px;
  }
}
</style>
