<template>
  <div :class="classes">
    <c-avatar
      class="avatar"
      hide-tooltip
      :src="userImage"
      :name-placeholder="userInitials"
    />

    <section class="content">
      <div class="name-header">
        <c-typo
          class="user"
          type="h2"
          opacity="base-80"
          weight="medium"
          color="text"
        >{{ userName }}</c-typo>

        <c-actions-button
          v-if="userAction"
          data-testid="actions-button"
          :class="actionClasses"
          iconSize="18"
          buttonSize="30"
          :items="actions"
          v-on=$listeners
        />

        <c-form-builder
          v-if="hasEdition"
          class="form"
          ref="form"
          no-actions
          show-overflow
          no-bottom-shadow
          no-upper-shadow
          :schema="schema"
          v-model="value"
          :errors="errors"
          show-errors-when-changed
          @submit="completeEdition"
          @keydown.native.enter="handleEnter"
          @keydown.native.esc="closeEdition"
        />

        <c-typo
          v-if="hasEdition"
          italic
          color="text"
          type="body-1"
          opacity="base-30"
          class="form-text"
        >{{ text }}</c-typo>

        <c-typo
          v-else
          class="message"
          type="body-1"
          opacity="base-50"
          color="text"
        >{{ message }}</c-typo>
      </div>

      <footer class="footer">
        <section class="files" v-if="(files || []).length">
          <c-info-tag
            v-for="(file, i) in files"
            :key="i"
            class="file"
            icon="clip-attachment"
            icon-size="18"
            icon-gap="2"
            @click.native="onFileClick(file)"
          >
            {{ file.name }}
          </c-info-tag>
        </section>

        <section class="information">
          <c-icon
            v-if="!isRemovedUser && isDelivered"
            class="delivered"
            size="15"
            icon="done-check-3"
          />

          <c-typo
            class="time"
            type="body-1"
            opacity="base-30"
            color="text"
          >{{ formattedTime }}</c-typo>
        </section>
      </footer>
    </section>
  </div>
</template>

<script>
import { dayjs } from '@convenia/utils'

export default {
  name: 'CChatBubble',

  props: {
    /**
     * An object representing the user details.
     * @type {Object}
     * @property {string} name - The name of the user.
     * @property {string} image - The URL of the user's image.
     */
    user: {
      type: Object,
      default: () => ({}),
    },

    /**
     * The message to be displayed.
     * @type {string}
     */
    message: {
      type: String,
      default: '',
    },

    /**
     * The time the message was sent.
     * @type {string|Date|number}
     */
    time: {
      type: [ String, Date, Number ],
      required: true,
    },

    /**
     * An array of files to be displayed.
     *
     * @typedef {Object} Item
     * @property {string} id - The unique identifier for the item.
     * @property {string} name - The name of the item.
     *
     * @type {Item[]}
     */
    files: {
      type: Array,
      default: () => [],
    },

    /**
     * A flag to indicate if the message was sent by the current user.
     * @type {boolean}
     */
    isCurrentUser: {
      type: Boolean,
      default: false,
    },

    /**
     * A flag to indicate if the user was removed.
     * @type {boolean}
     */
    isRemovedUser: {
      type: Boolean,
      default: false,
    },

    /**
     * A flag to indicate if the message was delivered.
     * @type {boolean}
     */
    isDelivered: {
      type: Boolean,
      default: false,
    },

    /**
     * A flag to indicate whether the message has buttons.
     * @type {Item[]}
     */
    actions: {
      type: Array,
      default: () => []
    },

    /**
     * List of fields errors.
     */
    errors: {
      type: Object,
      default: () => ({})
    },

    /**
     * A flag to indicate whether there is editing.
     * @type {boolean}
     */
    hasEdition: {
      type: Boolean,
      default: false,
    },

    /**
     * A flag to indicate whether the comment has been read.
     * @type {boolean|null}
     */
    isRead: {
      type: [ Boolean, null ],
      default: null,
    },

    /**
     * A flag to indicate whether the comment was written by the logged in user.
     * @type {boolean|null}
     */
    isLoggedUser: {
      type: [ Boolean, null ],
      default: null,
    },
  },

  data: () => ({
    newValue: '',
    text: 'Aperte <enter> para enviar, <shift+enter> para uma nova linha e <Esc> para cancelar.',
    schema: {
      comment: {
        type: 'text',
        textArea: true,
        maxlength: 1000,
        placeholder: 'Digite seu comentário',
      },
    },
  }),

  emits: [
    /**
     * Emits when a file is clicked.
     */
    'click:file',

    /**
     * Emits when the cancel button is clicked.
     */
    'action:cancel',

    /**
     * Emits when the save button is clicked.
     */
    'action:save',
  ],

  computed: {
    classes () {
      return [ 'c-chat-bubble', {
        '-current-user': this.isCurrentUser,
      } ]
    },

    userName () {
      return this.isRemovedUser
        ? 'Usuário excluído'
        : this.user?.name || ''
    },

    userInitials () {
      return this.isRemovedUser ? '' : this.userName
    },

    userImage () {
      return this.isRemovedUser ? null : this.user?.image
    },

    formattedTime () {
      return dayjs(this.time).format('HH:mm')
    },

    userAction () {
      return this.actions.length && !this.isCurrentUser &&
        !this.isRemovedUser && typeof this.isRead === 'boolean' &&
        typeof this.isLoggedUser === 'boolean'
    },

    value: {
      get () {
        return { comment: this.message || '' }
      },

      set (newValue) {
        this.newValue = newValue
      },
    },

    actionClasses () {
      return this.isRead || !this.isLoggedUser ? 'no-actions' : this.hasEdition
        ? 'edit-actions' : 'list-actions'
    },
  },

  methods: {
    onFileClick (file) {
      this.$emit('click:file', file)
    },

    handleEnter (event) {
      if (event?.shiftKey) return

      event?.preventDefault()
      this.triggerFormSubmit()
    },

    closeEdition () {
      this.newValue = this.message
      this.$emit('action:cancel', this.message)
    },

    completeEdition () {
      const { comment: newMessage } = this.newValue || {}
      const comment = newMessage === ''
        ? '' : newMessage || this.message

      this.$emit('action:save', comment.trim())
    },

    triggerFormSubmit () {
      this.$refs.form.onSubmit()
    },

    formFocus () {
      this.$nextTick(() => {
        this.$refs.form?.$refs?.fields?.[0]?.$el?.querySelector('textarea')?.focus()
      })
    },
  },
}
</script>

<style lang="scss">
.c-chat-bubble {
  --c-chat-bubble-avatar-margin-top: 16px;
  --c-chat-bubble-arrow-size: 13px;
  --c-chat-bubble-displacement: 5px;
  --c-chat-bubble-arrow-border-radius: 3px;

  %chat-bubble-arrow-common {
    content: '';
    position: absolute;
    top: calc(var(--c-chat-bubble-avatar-margin-top) + 15px);
    transform: rotate(45deg);
    width: var(--c-chat-bubble-arrow-size);
    height: var(--c-chat-bubble-arrow-size);
    background-color: inherit;
  }

  display: flex;
  align-items: flex-start;
  gap: 20px;

  &:hover > .content > .name-header > .list-actions { visibility: visible; }

  & > .avatar {
    margin-top: var(--c-chat-bubble-avatar-margin-top);
  }

  & > .content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: var(--base-border-radius);
    width: 100%;
    max-width: calc(100% - 60px);
    position: relative;

    & > .name-header {
      display: grid;
      grid-template-columns: 1fr auto;

      & > .user {
        grid-area: 1 / 1 / 2 / 2;
        margin-bottom: 5px;
      }

      & > .list-actions {
        grid-area: 1 / 2 / 3 / 3;
        visibility: hidden;
      }

      & > .edit-actions {
        grid-area: 1 / 2 / 3 / 3;
        margin-left: 50px;

        @media only screen and (max-width: 1024px) {
          margin-left: 20px;
        }
      }

      & > .no-actions {
        grid-area: 1 / 2 / 3 / 3;
        visibility: hidden;
      }

      & > .form {
        grid-area: 2 / 1 / 3 / 2;
        margin-top: 5px;

          & > .c-shadowed > .wrapper > .form-fields {
          padding: 0px;

          & > .c-form-field.-label-top {
            max-width: 100%;
          }

          & > .c-form-field {
            margin: 0;
            grid-template-columns: 100%;

            & > .input-wrapper > .c-input-raw > .c-input-abstract {
              min-height: 80px;
            }
          }
        }
      }

      & > .form-text {
        grid-area: 3 / 1 / 4 / 2;
        justify-self: end;
        margin-top: 10px;
      }

      & > .message {
        grid-area: 2 / 1 / 3 / 2;
        word-break: break-word;
        white-space: pre-line;
        margin-bottom: 15px;
        margin-right: 10px;
      }
    }

    & > .footer {
      display: flex;
      align-items: center;
      max-width: 100%;

      & > .files {
        flex-flow: row wrap;
        display: flex;
        gap: 10px;
        margin-top: 5px;
        max-width: inherit;

        @include responsive(xs-mobile, tablet) { margin-bottom: 15px; }

        & > .file {
          cursor: pointer;
          max-width: inherit;
        }
      }

      & > .information {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 10px;
        right: 15px;

        & > .delivered {
          margin-right: 5px;
          stroke: color-var(info, base-70);
        }

        & > .time {
          margin-right: 4px;
        }
      }
    }
  }

  &:not(.-current-user) {
    & > .content {
      background-color: rgba-to-rgb(map-get($text-color, base-02));

      &::before {
        @extend %chat-bubble-arrow-common;
        left: calc(-1 * var(--c-chat-bubble-displacement));
        border-radius: 0 0 0 var(--c-chat-bubble-arrow-border-radius);
        clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
      }
    }
  }

  &.-current-user {
    flex-direction: row-reverse;

    & > .content {
      background-color: color-var(positive, lighter);

      &::after {
        @extend %chat-bubble-arrow-common;
        right: calc(-1 * var(--c-chat-bubble-displacement));
        border-radius: 0 var(--c-chat-bubble-arrow-border-radius) 0 0;
        clip-path: polygon(100% 100%, 0% 0%, 100% 0%);
      }
    }
  }
}
</style>
