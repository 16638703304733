import { dayjs } from '@convenia/utils'

const isNumeric = value => {
  return value.length > 0 ? /^\d+$/.test(value) : true
}

export default ctx => ({
  rg: {
    number: {
      type: 'text',
      placeholder: 'Número',
      label: 'Número',
      value: ''
    },
    emission_date: {
      type: 'date',
      placeholder: 'Data de emissão',
      label: 'Data de emissão',
      validation: {
        date: true
      },
      enableInput: true,
      value: ''
    },
    issuing_agency: {
      type: 'text',
      placeholder: 'Órgão emissor',
      label: 'Órgão emissor',
      value: ''
    },
    issuing_state: {
      type: 'select',
      placeholder: 'UF Emissor',
      label: 'UF Emissor',
      value: '',
      displayBy: 'name',
      trackBy: 'id',
      options: [],
      optionsSrc: 'issuing_state'
    },
  },

  cpf: {
    cpf: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'Número',
      label: 'Número',
      mask: '###.###.###-##',
      validation: { cpf: true },
      value: '',
    },
  },

  driver_license: {
    number: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'Número',
      label: 'Número',
      value: ''
    },
    emission_date: {
      type: 'date',
      placeholder: 'Data de emissão',
      label: 'Data de emissão',
      validation: {
        date: true
      },
      enableInput: true,
      value: ''
    },
    validate_date: {
      type: 'date',
      placeholder: 'Data de validade',
      label: 'Data de validade',
      validation: {
        date: true,
        custom: ({ value, formValue }) => {
          const emissionDate = dayjs(formValue.emission_date)

          return dayjs(value).isAfter(emissionDate)
            || 'Data de validade inválida.'
        },
      },
      enableInput: true,
      value: ''
    },
    category: {
      type: 'text',
      placeholder: 'Categoria',
      label: 'Categoria',
      value: ''
    },
  },

  ctps: {
    number: {
      type: 'text',
      placeholder: 'Número',
      inputmode: 'numeric',
      label: 'Número',
      value: '',
      validation: {
        maxLength: 20,
        custom: (_, value) => {
          return !isNumeric(value) ? 'Formato inválido' : true
        }
      }
    },
    serial_number: {
      type: 'text',
      placeholder: 'Série',
      label: 'Série',
      inputmode: 'numeric',
      value: '',
      validation: {
        custom: (_, value) => {
          return !isNumeric(value) ? 'Formato inválido' : true
        }
      }
    },
    emission_date: {
      type: 'date',
      placeholder: 'Data de emissão',
      label: 'Data de emissão',
      validation: {
        date: true
      },
      enableInput: true,
      value: ''
    },
    issuing_state: {
      type: 'select',
      placeholder: 'UF Emissor',
      label: 'UF Emissor',
      value: '',
      displayBy: 'name',
      trackBy: 'id',
      options: [],
      optionsSrc: 'states'
    },
    pis: {
      type: 'text',
      placeholder: 'PIS',
      inputmode: 'numeric',
      label: 'PIS',
      mask: '###.#####.##-#',
      validation: 'pis',
      removeSpecialCharacter: true,
      value: ''
    },
  },

  electoral_card: {
    number: {
      type: 'text',
      inputmode: 'numeric',
      placeholder: 'Número',
      label: 'Número',
      value: ''
    },
    electoral_ward: {
      type: 'text',
      placeholder: 'Zona eleitoral',
      label: 'Zona eleitoral',
      value: ''
    },
    section: {
      type: 'text',
      placeholder: 'Seção eleitoral',
      label: 'Seção eleitoral',
      value: ''
    },
    state: {
      type: 'select',
      placeholder: 'UF',
      label: 'UF',
      value: '',
      displayBy: 'name',
      trackBy: 'id',
      options: [],
      optionsSrc: 'states',
      onInput: (formData) => {
        ctx.getCities(formData.state)

        if (formData.city) formData.city = undefined
      },
      infoCardProps: { hide: () => true }
    },
    city: {
      type: 'select',
      placeholder: 'Município',
      label: 'Município',
      value: '',
      displayBy: 'name',
      trackBy: 'id',
      options: [],
      optionsSrc: 'cities',
      infoCardProps: { hide: () => true }
    },
  },

  reservist: {
    reservist: {
      type: 'text',
      inputmode: 'number',
      placeholder: 'Número',
      label: 'Número',
      value: '',
      validation: {
        max: 50
      }
    },
    ra_number: {
      type: 'text',
      inputmode: 'number',
      placeholder: 'RA',
      label: 'RA',
      value: '',
      validation: {
        max: 50
      }
    },
    series: {
      type: 'text',
      placeholder: 'Categoria',
      label: 'Categoria',
      value: '',
      validation: {
        max: 1
      }
    },
  },

  dismissal: {
    motive: {
      type: 'text',
      label: 'Motivo do Desligamento'
    },
    comments: {
      type: 'text',
      label: 'Observações'
    }
  },

  other_document: {
    name: {
      type: 'text',
      placeholder: 'Nome',
      label: 'Nome',
      required: true,
      validation: 'required',
      value: '',
      infoCardProps: { hide: () => true }
    },
    observation: {
      type: 'text',
      validation: { max: 220 },
      rows: 2,
      textArea: true,
      placeholder: 'Observações',
      label: 'Observações',
      value: ''
    },
  }
})
