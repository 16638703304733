<template>
  <div class="c-file" :class="classes">
    <c-selectable
      v-if="hasSelector"
      class="desktop-selectable"
      :selected="isSelected"
      @toggle="$emit('toggle', $event)"
    />

    <div
      class="card"
      @click="$emit('click')"
    >
      <div class="main">
        <c-selectable
          v-if="hasSelector"
          ref="mobile-selectable"
          class="mobile-selectable"
          :selected="isSelected"
          @toggle="$emit('toggle', $event)"
          @click.native.stop
        />

        <c-employee-info
          grey
          icon-size="24"
          :icon="icon"
          :name="title"
        >
          <c-progress class="progress" :value="progress" />

          <span class="type" v-html="error || formatedSize || type" />

          <span class="success">
            <c-icon icon="done-check-3" size="8" />
          </span>
        </c-employee-info>

        <c-label
          v-if="label && !uploading"
          success
          thick
        >
          {{ label }}
        </c-label>

        <div class="actions">
          <!-- @slot Components or sample buttons to add more functionalities -->
          <slot name="actions" />

          <c-button
            v-if="downloadable"
            data-testid="download-button"
            flat
            size="30"
            :disabled="disabled"
            icon="download-arrow"
            icon-size="18"
            type="button"
            class="btn-download action"
            @click.stop="$emit('download')"
          />

          <c-button
            v-if="remove"
            data-testid="remove-button"
            flat
            error
            size="30"
            :disabled="disabled"
            :icon="uploading && !error ? 'square' : 'trash'"
            :loading="isRemoving"
            icon-size="18"
            type="button"
            class="btn-delete action"
            @click.stop="$emit('remove')"
          />
        </div>
      </div>

      <div v-if="nestedFile || nestable" class="nested-file-upload">
        <c-file
          v-if="nestedFile"
          :name="nestedFile.name"
          :size="nestedFile.size"
          :done="nestedFile.done"
          :uploading="nestedFile.uploading"
          :progress="nestedFile.progress"
          :label="nestedFile.label"
          :transparent="transparent"
          :error="$parent.getErrorMessage(nestedFile)"
          :downloadable="downloadable || downloadNested"
          :remove="remove || removeNested"
          @click.native="$emit('click', { nested: true })"
          @remove="$emit('nested-remove', nestedFile)"
          @download="$emit('download', { nested: true })"
        />

        <div
          v-else
          ref="upload-box"
          class="upload-box"
          @click.stop="$emit('nested-add')"
          @drop.prevent.stop="$emit('nested-drag', $event)"
          @dragover.prevent.stop
          @dragenter.prevent.stop
          @dragleave.prevent.stop
        >
          <p class="text">{{ uploadText }}</p>

          <p class="extensions-text">{{ extensionsText }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import data from './data'

const unitMultipliers = {
  'B': 1,
  'KB': 1000,
  'MB': 1000000,
  'GB': 1000000000
}

export default {
  name: 'CFile',

  emits: [
    /**
      When click in the file or nested file
      @property {object}
      @property {boolean} nested - true if was nested file
    */
    'click',
    /**
      When click in remove button in file
    */
    'remove',
    /**
      When click in selectable component
    */
    'toggle',
    /**
      When click in download button in file
      @property {object}
      @property {boolean} nested - true if was nested file
    */
    'download',
    /**
      When click in remove button in nested file
      @property {object} nestedFile
    */
    'nested-remove',
    /**
      When click in add section for add nested files in the file
    */
    'nested-add',
    /**
      When drag file into drag section for add nested files in the file
      @property {event} event
    */
    'nested-drag'
  ],

  props: {
    /**
    * Name of file
    */
    name: {
      type: String,
      default: ''
    },

    /**
    * Show file in error mode and show the message
    */
    error: {
      type: String,
      default: ''
    },

    /**
    * Size of file in bytes
    */
    size: {
      type: [ String, Number ],
      default: null
    },

    /**
    * Turn file in upload mode
    */
    uploading: {
      type: Boolean,
      default: false
    },

    /**
    * Show icon and turn able to remove this file
    */
    remove: {
      type: Boolean,
      default: true
    },

    /**
    * Show icon and turn able to download this file
    */
    downloadable: {
      type: Boolean,
      default: false
    },

    /**
    * Progress of file uploading
    */
    progress: {
      type: [ Number, String ],
      default: 0
    },

    /**
    * Turn file and the actions disabled
    */
    disabled: {
      type: Boolean,
      default: false
    },

    /**
     * Allowed file extensions for nested files (preferably in lowercase)
     */
    extensions: {
      type: Array,
      default: () => [],
    },

    /**
     * Allows you to choose the size unit for the files
     */
    sizeUnit: {
      type: String,
      default: 'MB',
      validator: v => [ 'B', 'KB', 'MB', 'GB' ].includes(v)
    },

    /**
     * Makes the file background transparent
     */
    transparent: Boolean,

    /**
     * Show a c-selectable next to the file
     */
    hasSelector: Boolean,

    /**
     * Whether the selectable is checked or not
     */
    isSelected: Boolean,

    /**
     * Displays a loader when removing the file via async actions
     */
    isRemoving: Boolean,

    /**
     * Show a CLabel in the file the the corresponding text
     */
    label: {
      type: String,
      default: '',
    },

    /**
     * The text shown at the top of the nested upload box (when it is available)
     */
    uploadText: {
      type: String,
      default: 'Arraste seu arquivo e solte aqui'
    },

    /**
     * Allows it to upload / show a nested file
     */
    nestable: Boolean,

    /**
     * The nested file Object per se, preferrably an instance of CUploadFile
     */
    nestedFile: {
      type: Object,
      default: null,
    },

    /**
     * Whether to show the remove option only for nested files
     */
    removeNested: Boolean,

    /**
     * Whether to show the download option only for nested files
     */
    downloadNested: Boolean,
  },

  computed: {
    classes () {
      return {
        '-is-uploading': this.uploading && !this.error,
        '-is-complete': +this.progress >= 100,
        '-has-error': !!this.error,
        '-transparent': this.transparent,
        '-is-removing': this.isRemoving,
        '-nestable': this.nestable
      }
    },

    title () {
      if (this.uploading && this.size)
        return `${this.name} - ${this.formatedSize}`

      return this.name
    },

    extension () {
      const index = this.name.lastIndexOf('.')

      return (index < 1) ? '' : this.name.substr(index + 1).toLowerCase()
    },

    type () {
      return (data[this.extension] || {}).type || 'Não identificado'
    },

    icon () {
      return (data[this.extension] || {}).icon || 'question-circle'
    },

    formatedSize () {
      const multiplier = this.size < unitMultipliers['KB'] ? 'B'
        : this.size < unitMultipliers['MB'] ? 'KB'
          : this.size < unitMultipliers['GB'] ? 'MB' : 'GB'
      const filesize = this.size / unitMultipliers[multiplier]

      return `${filesize.toFixed(2)} ${multiplier}`
    },

    extensionsText () {
      if (!this.extensions) return

      const mapped = this.extensions.map(ext => `.${ext.toUpperCase()}`)
      const pre = 'Nós aceitamos os arquivos que estão no \nformato'
      const mid = mapped.slice(0, -1).join(', ')

      return `${pre} ${mid} e ${mapped[mapped.length - 1]}.`
    }
  }
}
</script>

<style lang="scss">
.c-file {
  align-items: center;
  display: flex;
  min-width: 0;

  & > .c-selectable.desktop-selectable {
    margin-right: 20px;
    display: none;
  }

  & > .card {
    flex: 1;
    min-width: 0;
    background: #FFF;
    border: var(--base-border);
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    box-sizing: border-box;
    transition: background .2s ease-out;

    & > .main {
      padding: 5px;
      padding-right: 10px;
      display: flex;
      position: relative;
      align-items: center;

      & > .c-selectable.mobile-selectable { margin-right: 5px; }

      & > .thumbnail {
        width: 50px;
        height: 50px;
        background-size: cover;
        background-position: center;
        border-radius: 2px;
        flex-shrink: 0;
      }

      & > .c-employee-info {
        flex: 1;
        min-width: 0;
        position: relative;

        & > .info {
          min-width: 0;

          & > .name {
            font-size: 14px !important;
            font-weight: var(--title-font-weight);
            line-height: 11px;
            transition: .2s ease-out;
            -webkit-font-smoothing: antialiased;
            @include truncate(100%);
          }

          & > .type {
            font-size: 12px !important;
            font-family: var(--title-font-family);
            transition: .2s ease-out;
            @include truncate(100%);
          }

          & > .progress {
            position: absolute;
            width: auto;
            left: 50px;
            right: 20%;
            opacity: 0;
            visibility: hidden;
            transition: .2s ease-out;
            transform: translateY(-120%);
          }

          & > .success {
            position: absolute;
            bottom: -2px;
            left: 25px;
            width: 18px;
            height: 18px;
            border-radius: 20px;
            background: linear-gradient(315deg, #3CE798 0%, #37CFCC 100%);
            border: 2px solid white;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: scale(2);
            opacity: 0;
            visibility: hidden;
            transition: .2s ease-out;
            z-index: var(--z-index-1);

            & > .c-icon { @include icon-color(white); }
          }
        }
      }

      & > .c-label { display: none; }

      & > .actions {
        margin-left: 20px;
        display: flex;

        & > .action:not(:last-child) { margin-right: 10px; }
      }
    }

    & > .nested-file-upload {
      margin-top: 10px;

      & > .upload-box {
        height: 80px;
        border-radius: 5px;
        border: 1px dashed color-var(text, base-20);
        background-color: color-var(text, base-02);
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > .text {
          text-align: center;
          font-family: var(--title-font-family);
          font-size: 12px;
          font-weight: 500;
        }

        & > .extensions-text {
          text-align: center;
          white-space: pre;
          @include typo(body-2, base-30);
          font-size: 12px;
        }

        @include responsive (xs-mobile, mobile) {
          height: fit-content;
          padding: 5px 20px;

          & > .extensions-text { white-space: pre-wrap; }
        }
      }
    }
  }

  &.-transparent > .card { background: rgba(255, 255, 255, 0); }

  &.-is-uploading > .card > .main > .c-employee-info  > .info {
    & > .name,
    & > .type {
      padding-right: 30px;
      transform: translateY(120%);
    }

    & > .type { opacity: 0; }

    & > .progress {
      right: 0%;
      transform: translateY(0%);
      opacity: 1;
      visibility: visible;
    }
  }

  &.-is-complete:not(.-has-error) > .card > .main > .c-employee-info > .info > .success {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  &.-has-error > .card {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate(0, 0);
    border-color: transparent;
    background: rgba(color-var(negative, base-rgb), .2) !important;

    & > .main > .c-employee-info {
      & > .c-flag { background: rgba(color-var(negative, base-rgb), .1); }

      & > .c-flag > .c-icon { @include icon-color(negative); }

      & > .info > .type,
      & > .info > .name { color: color-var(negative); }
    }
  }

  @include responsive(tablet, desktop) {
    & > .c-selectable.desktop-selectable {
      display: block;
    }

    &:not(.-nestable) > .card > .main > .actions > .action {
      opacity: 0;
      will-change: opacity;
    }

    & > .card {
      & > .main  {
        & > .c-selectable.mobile-selectable { display: none; }
        & > .c-label { display: flex; }
      }

      &:hover {
        background: #fafafb;
        & > .main > .actions > .action { opacity: 1; }
      }

      & > .nested-file-upload > .upload-box {
        & > .text {
          margin-bottom: 5px;
          @include typo(h3);
        }

        & > .extensions-text {
          @include typo(body-2, base-30);
        }
      }
    }
  }

  &.-is-removing > .card > .main > .actions > .btn-delete {
    opacity: 1;
  }

  @keyframes shake {
    10%, 90% { transform: translate(-1px, 0); }
    20%, 80% { transform: translate(2px, 0); }
    30%, 50%, 70% { transform: translate(-4px, 0); }
    40%, 60% { transform: translate(4px, 0); }
  }
}
</style>
