<template>
  <header
    class="c-info-header"
    :class="{ '-opened': opened, '-aligned': !!margin, '-collapsable': collapse }"
    :style="styles"
    @click.stop="$emit('toggle')"
  >
    <c-button
      v-if="previous"
      flat
      type="button"
      class="previous"
      icon="arrow-left"
      icon-size="20"
      @click="$emit('previous')"
    />

    <c-title
      v-if="titleComponent == 'c-title'"
      :highlight="highlight"
      :grey="!opened && isMobile"
    >
      {{ title }}
    </c-title>

    <c-employee-info
      v-else
      hide-avatar-tooltip
      icon-size="24"
      :parse-text="false"
      :icon="icon"
      :name="title"
      :src="src"
      :highlight="highlight"
      v-bind="iconTheme"
    >
      <span v-if="subhead" class="subhead">
        {{ subhead }}
      </span>

      <slot name="content" />
    </c-employee-info>

    <div class="actions">

      <div class="share">
        <c-flag
          v-if="share"
          v-tooltip.dark="tooltip"
          class="share-flag"
          icon="share-1"
          :icon-size="24"
        />
      </div>

      <slot name="before-actions" />

      <div
        v-if="edit && opened || edit && !collapse"
        class="edit-wrapper"
      >
        <transition name="loading-fade">
          <c-spinner
            v-if="loading"
            color="rgba(18, 30, 72, 0.2)"
            :size="buttonSize + 2"
          />
        </transition>

        <c-button
          v-if="extra"
          flat
          class="extra-btn"
          type="button"
          :icon="extra"
          :size="buttonSize"
          :icon-size="iconSize"
          @click.stop="$emit('extra')"
        />

        <c-button
          data-testid="info-edit-button"
          flat
          type="button"
          icon="pen-edit-17"
          :disabled="loading"
          :icon-size="iconSize"
          :size="buttonSize"
          @click.stop="$emit('edit')"
        />
      </div>

      <c-button
        v-if="close"
        flat
        type="button"
        icon="delete-disabled"
        :icon-size="iconSize"
        :size="buttonSize"
        @click.stop="$emit('close')"
      />

      <c-button
        v-if="remove && opened || remove && !collapse"
        data-testid="info-remove-button"
        type="button"
        flat
        error
        icon="trash"
        :icon-size="iconSize"
        :size="buttonSize"
        @click.stop="$emit('remove')"
      />

      <c-button
        v-if="isMobile && add && opened"
        data-testid="info-add-button"
        type="button"
        size="30"
        icon="plus"
        icon-size="20"
        @click.stop="$emit('add')"
      />

      <c-button
        v-else-if="!isMobile && add || add && !collapse"
        primary
        type="button"
        icon-size="20"
        icon="plus"
        @click.stop="$emit('add')"
      >
        Adicionar
      </c-button>

      <c-button
        v-if="collapse"
        flat
        type="button"
        class="collapse"
        icon="arrow-left"
        :icon-size="iconSize"
        :size="buttonSize"
        @click.stop="$emit('toggle')"
      />

      <slot name="actions" />
    </div>
  </header>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'CInfoHeader',

  mixins: [ MediaQuery ],

  props: {
    /**
     * The header title.
     */
    title: {
      type: String,
      default: ''
    },

    /**
     * The component used to the title. It can be either 'c-title' or 'c-employee-info'.
     */
    titleComponent: {
      type: String,
      default: 'c-title',
      validator: value => [ 'c-title', 'c-employee-info' ].includes(value)
    },

    /**
     * The header subhead. Only displayed when the **titleComponent** is equal to 'c-employee-info'.
     */
    subhead: {
      type: String,
      default: null
    },

    /**
     * The theme color. 'default' for gray; 'primary' for purple. Only displayed when the
     * **titleComponent** is equal to 'c-employee-info'.
     */
    theme: {
      type: String,
      default: 'default'
    },

    /**
     * The icon name. Only displayed when the **titleComponent** is equal to 'c-employee-info'.
     */
    icon: {
      type: String,
      default: ''
    },

    /**
     * The action buttons size. 'sm' for small; 'md' for medium.
     */
    actionSize: {
      type: String,
      default: 'sm',
      validator: value => [ 'sm', 'md' ].includes(value)
    },

    /**
     * Displays the add action button.
     */
    add: Boolean,

    /**
     * Displays the extra action button.
    /* Your value is the name of the icon
     */
    extra: {
      type: String,
      default: ''
    },

    share: Boolean,

    /**
     * Displays the edit action button.
     */
    edit: Boolean,

    /**
     * Displays the remove action button.
     */
    remove: Boolean,

    /**
     * Displays the close action button.
     */
    close: Boolean,

    /**
     * Displays the collapse action button.
     */
    collapse: Boolean,

    /**
     * Displays the previous button before the title.
     */
    previous: Boolean,

    /**
     * Indicates whether the collapse is opened or closed.
     */
    opened: Boolean,

    /**
     * Displays the loading spinner on top of the edit action button.
     */
    loading: Boolean,

    /**
     * Margin used to align the title.
     */
    margin: {
      type: [ Number, Boolean ],
      default: false,
    },

    /**
     * Highlights the title
     */
    highlight: Boolean,

    /**
     * Source of the avatar photo
     */
    src: {
      type: String,
      default: '',
    },

    tooltip: {
      type: String,
      default: ''
    }
  },

  computed: {
    styles () {
      return {
        '--margin-flag': (this.margin - 40 - 20 - 10) + 'px',
        '--margin-text': 30 + 'px'
      }
    },

    buttonSize () {
      return this.actionSize === 'sm' ? 30 : 40
    },

    iconSize () {
      return this.actionSize === 'sm' ? 18 : 24
    },

    iconTheme () {
      const mapper = {
        default: 'grey',
        primary: 'primary',
        error: 'error',
        success: 'success',
        accent: 'accent',
      }
      return { [mapper[this.theme] || 'grey']: true }
    }
  }
}
</script>

<style lang="scss">
.c-info-header {
  width: 100%;
  display: flex;
  align-items: center;
  min-width: 0;

  & > .previous {
    margin-right: 15px;
    @include responsive (xs-mobile, mobile) { margin-right: 10px; }

    & > .c-icon {
      position: relative;
      right: 1px;
    }
  }

  & > .c-title {
    min-width: 0;

    & > .text {
      color: color-var(text, base-80);
      @include truncate(100%);
    }
  }

  & > .c-employee-info {
    min-width: 0;
    flex: 1;

    & > .info {
      min-width: 0;
      padding-left: 15px;

      & > .subhead { @include truncate(100%); }
    }
  }

  & > .actions {
    display: flex;
    align-items: center;
    margin-left: auto;

    & > .share {
      display: flex;
      border-right: 1px solid var(--color-text-base-10);
      margin-right: 10px;

      & > .share-flag {
      display: flex;
      margin-right: 10px;

      & > .c-icon.-convenia {
        stroke: #121E48;
        opacity: 0.3;
      }

      &:hover {
        & > .c-icon.-convenia {
          opacity: 0.5;
        }
      }
    }
  }

    & > .c-button { margin-left: 10px; }

    & > .edit-wrapper {
      display: flex;
      margin-left: 10px;
      position: relative;

      & > .extra-btn { margin-right: 10px; }

      & > .c-spinner {
        position: absolute;
        top: -1px;
        left: -1px;
        pointer-events: none;
      }
    }

    & > .collapse {
      padding: 0 2px 2px 0;
      transform: rotate(270deg);
      transition: .2s ease-out;

      & > .icon { margin-bottom: -1px; }
    }
  }

  &.-opened {
    & > .actions > .collapse { transform: rotate(90deg); }

    &.-aligned {
      @include responsive (tablet, desktop) {
        & > .c-employee-info {
          margin-left: var(--margin-flag);

          & > .info { padding-left: var(--margin-text); }
        }
      }
    }
  }

  &.-collapsable { cursor: pointer; }

  .loading-fade {
    &-enter-active, &-leave-active { transition: .5s; }
    &-enter, &-leave-to { opacity: 0; }
  }
}
</style>
