import { is } from '@convenia/helpers'

export const Loadable = (options = {
  multiplePromises: false
}) => ({
  data: () => ({ isLoading: true, hasError: false }),

  async mounted () {
    await this.__loadData()
  },

  methods: {
    async __loadData () {
      const isLoadable = is(this.load, 'Function') || is(this.load, 'AsyncFunction')

      if (!isLoadable) return (this.isLoading = false)

      const data = await this.load() || [ null, null ]

      if (options.multiplePromises)
        this.hasError = data.some(promiseData => !!(promiseData || [])[0])
      else
        this.hasError = !!data[0]

      this.isLoading = false
      this.$emit('loaded', { hasError: this.hasError })
    },

    async __reloadData () {
      this.isLoading = true
      await this.__loadData()
    }
  }
})
