import * as set from '@graphql/mutations'
import { formatDateUS } from '@convenia/helpers'
import { mapToNumeric } from '@modules/formatters'
import { getEmployeeInfoSchema, getAdditionalFields } from '@services/systemField'
import { PERSONAL_DOCUMENTS_AREA } from '@content/consts/Documents'

import * as middlewareGet from '@graphql/middleware/queries'
import middleware from '@graphql/middleware'
import {
  documentMappers as mappers,
  mapDocuments,
  mapCustomFields,
  mapCustomDocument,
  getUploadEndpoint,
} from '@convenia/documents-organisms/content/mappers'

export const getDocuments = async (params) => {
  const { employeeId } = params

  const [ err, response ] = await middleware(middlewareGet.GetDocuments, { employeeId }) || {}
  const { employee: data = {} } = response

  const documents = mapDocuments(data, params)

  return [ err, documents ]
}

export const getCustomDocuments = async (params) => {
  const { employeeId, companyId } = params
  const input = { employeeId, companyId, isAdmission: true }

  const [ err, data ] = await middleware(middlewareGet.GetCustomDocuments, input)

  const customDocuments = data?.customDocuments?.map(
    document => mapCustomDocument(document, params)
  ) || []

  return [ err, customDocuments ]
}

export const getCustomFields = async (params) => {
  const { employeeId } = params

  const [ err, data ] = await getEmployeeInfoSchema({
    employeeId,
    area: PERSONAL_DOCUMENTS_AREA.LABEL
  })

  const customFields = mapCustomFields(data)

  return [ err, customFields ]
}

export const setRg = async (variables, params) => {
  const { apiUrl, employeeId, companyId } = params
  const isCreating = !variables.id
  const mutation = isCreating ? 'CreateRg' : 'UpdateRg'
  const input = {
    ...(!isCreating ? { id: variables.id } : {}),
    employee_id: employeeId,
    number: variables.number,
    emission_date: formatDateUS(variables.emission_date),
    issuing_agency: variables.issuing_agency,
    issuing_state_id: +variables.issuing_state || null,
    additional: {
      sectionId: PERSONAL_DOCUMENTS_AREA.RG,
      fields: getAdditionalFields(variables.customFields, variables)
    }
  }

  const [ err, data ] = await middleware(set[mutation], { input })

  if (err)
    return [ err, null, null ]

  const document = isCreating
    ? mappers.rg(data?.rg, params)
    : variables

  const endpoint = getUploadEndpoint(apiUrl, employeeId, companyId, document.id, 'rg')

  return [ err, document, endpoint ]
}

export const setDriverLicense = async (variables, params) => {
  const { apiUrl, employeeId, companyId } = params
  const isCreating = !variables.id
  const mutation = isCreating ? 'CreateDriverLicense' : 'UpdateDriverLicense'
  const input = {
    ...(!isCreating ? { id: variables.id } : {}),
    employee_id: employeeId,
    number: variables.number,
    emission_date: formatDateUS(variables.emission_date),
    validate_date: formatDateUS(variables.validate_date),
    category: variables.category,
    additional: {
      sectionId: PERSONAL_DOCUMENTS_AREA.CNH,
      fields: getAdditionalFields(variables.customFields, variables)
    }
  }

  const [ err, data ] = await middleware(set[mutation], { input })

  if (err)
    return [ err, null, null ]

  const document = isCreating
    ? mappers.driver_license(data?.driver_license, params)
    : variables

  const endpoint = getUploadEndpoint(apiUrl, employeeId, companyId, document.id, 'driver_license')

  return [ err, document, endpoint ]
}

export const setCpf = async (variables, params) => {
  const { apiUrl, employeeId, companyId } = params
  const isCreating = !variables.id
  const mutation = isCreating ? 'CreateCpf' : 'UpdateCpf'
  const input = {
    ...(!isCreating ? { id: variables.id } : {}),
    employee_id: employeeId,
    cpf: mapToNumeric(variables.cpf),
    additional: {
      sectionId: PERSONAL_DOCUMENTS_AREA.CPF,
      fields: getAdditionalFields(variables.customFields, variables)
    }
  }

  const [ err, data ] = await middleware(set[mutation], { input })

  if (err)
    return [ err, null, null ]

  const document = isCreating
    ? mappers.cpf(data?.cpf, params)
    : variables

  const endpoint = getUploadEndpoint(apiUrl, employeeId, companyId, document.id, 'cpf')

  return [ err, document, endpoint ]
}

export const setReservist = async (variables, params) => {
  const { apiUrl, employeeId, companyId } = params
  const isCreating = !variables.id
  const mutation = isCreating ? 'CreateReservist' : 'UpdateReservist'
  const input = {
    ...(!isCreating ? { id: variables.id } : {}),
    employee_id: employeeId,
    reservist: variables.reservist,
    ra_number: variables.ra_number,
    series: variables.series,
    additional: {
      sectionId: PERSONAL_DOCUMENTS_AREA.RESERVIST,
      fields: getAdditionalFields(variables.customFields, variables)
    }
  }

  const [ err, data ] = await middleware(set[mutation], { input })

  if (err)
    return [ err, null, null ]

  const document = isCreating
    ? mappers.reservist(data?.reservist, employeeId)
    : variables

  const endpoint = getUploadEndpoint(apiUrl, employeeId, companyId, document.id, 'reservist')

  return [ err, document, endpoint ]
}

export const setElectoralCard = async (variables, params) => {
  const { apiUrl, employeeId, companyId } = params
  const isCreating = !variables.id
  const mutation = isCreating ? 'CreateElectoralCard' : 'UpdateElectoralCard'
  const input = {
    ...(!isCreating ? { id: variables.id } : {}),
    employee_id: employeeId,
    number: variables.number,
    electoral_ward: mapToNumeric(variables.electoral_ward),
    section: mapToNumeric(variables.section),
    state_id: +variables.state || null,
    city_id: +variables.city || null,
    additional: {
      sectionId: PERSONAL_DOCUMENTS_AREA.ELECTORAL,
      fields: getAdditionalFields(variables.customFields, variables)
    }
  }

  const [ err, data ] = await middleware(set[mutation], { input })

  if (err)
    return [ err, null, null ]

  const document = isCreating
    ? mappers.electoral_card(data?.electoral_card, employeeId)
    : variables

  const endpoint = getUploadEndpoint(apiUrl, employeeId, companyId, document.id, 'electoral_card')

  return [ err, document, endpoint ]
}

export const setCtps = async (variables, params) => {
  const { apiUrl, employeeId, companyId } = params
  const isCreating = !variables.id
  const mutation = isCreating ? 'CreateCtps' : 'UpdateCtps'
  const input = {
    ...(!isCreating ? { id: variables.id } : {}),
    employee_id: employeeId,
    emission_date: formatDateUS(variables.emission_date),
    number: variables.number,
    pis: variables.pis,
    serial_number: variables.serial_number,
    issuing_state_id: +variables.issuing_state || null,
    additional: {
      sectionId: PERSONAL_DOCUMENTS_AREA.CTPS,
      fields: getAdditionalFields(variables.customFields, variables)
    }
  }

  const [ err, data ] = await middleware(set[mutation], { input })

  if (err)
    return [ err, null, null ]

  const document = isCreating
    ? mappers.ctps(data.ctps, employeeId)
    : variables

  const endpoint = getUploadEndpoint(apiUrl, employeeId, companyId, document.id, 'ctps')

  return [ err, document, endpoint ]
}

export const setCustomDocument = async (variables, params) => {
  const { apiUrl, employeeId, companyId } = params
  const sectionId = variables.id
  const input = {
    employeeId,
    hasAttachment: true,
    additional: {
      sectionId,
      fields: getAdditionalFields(variables.customFields, variables)
    }
  }

  const [ err, data ] = await middleware(set.UpdateCustomForm, { input })

  if (err)
    return [ err, null, null ]

  const { values } = data || {}
  const { attachmentId } = variables
  const { id: documentId } = values?.find(value => value.customFieldId === attachmentId) || {}
  const endpoint = getUploadEndpoint(apiUrl, employeeId, companyId, documentId, 'custom_field_value')

  return [ err, data, endpoint ]
}
