import { fileOpenMethod } from '@convenia/helpers'

const getFileName = (name = '', extension) =>
  !name.includes('.') && extension
    ? name + '.' + extension
    : name

export const getFileUrl = async (params) => {
  const { apiUrl, employeeId, companyId, token, fileId } = params
  const url = `${apiUrl}/employee/${employeeId}/file/${fileId}/previewFile`

  return fetch(url, {
    headers: {
      'Accept': 'application/json',
      ...(companyId ? { 'Active-Company': companyId } : {}),
      ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
    }
  })
    .then(response => response.blob())
    .then(blob => (window.URL || window.webkitURL).createObjectURL(blob))
}

export const mapFiles = (files, params) => {
  const { apiUrl, employeeId, companyId, token } = params || {}

  return (files || []).map(({ id, fileName, extension, size }) => ({
    id,
    size,
    url: () => getFileUrl({ apiUrl, employeeId, companyId, token, fileId: id }),
    name: getFileName(fileName, extension),
    preview: () => getFileUrl({ apiUrl, employeeId, companyId, token, fileId: id }),
    openMethod: fileOpenMethod,
  }))
}

export const getUploadEndpoint = (apiUrl, employeeId, companyId, entityId, type) =>
  `${apiUrl}/company/${companyId}/employee/${employeeId}/document/${entityId}/type/${type}/upload`

export const getFileDeleteEndpoint = (apiUrl, fileId, employeeId) =>
  `${apiUrl}/employee/${employeeId}/document/file/${fileId}`
