// Photo
export const EMPLOYEE_PHOTO = 'employee/PHOTO'
export const EMPLOYEE_PHOTO_SET = 'employee/PHOTO_SET'

// Personal Info
export const EMPLOYEE_PERSONAL_INFO = 'employee/EMPLOYEE_PERSONAL_INFO'
export const EMPLOYEE_PERSONAL_CUSTOM_FIELDS = 'employee/EMPLOYEE_PERSONAL_CUSTOM_FIELDS'
export const EMPLOYEE_PERSONAL_OPTIONS = 'employee/EMPLOYEE_PERSONAL_OPTIONS'
export const EMPLOYEE_UPDATE_PERSONAL = 'employee-personal/EMPLOYEE_UPDATE_PERSONAL'
export const EMPLOYEE_UPDATE_FOREIGN = 'employee-personal/EMPLOYEE_UPDATE_FOREIGN'
export const EMPLOYEE_FOREIGN_VISA_TYPES = 'employee-personal/EMPLOYEE_FOREIGN_VISA_TYPES'
export const EMPLOYEE_UPDATE_HANDICAP = 'employee-personal/EMPLOYEE_UPDATE_HANDICAP'
export const EMPLOYEE_UPDATE_REAL_ESTATE = 'employee-personal/EMPLOYEE_UPDATE_REAL_ESTATE'
export const EMPLOYEE_PERSONAL_SCHEMA = 'employee/EMPLOYEE_PERSONAL_SCHEMA'

// Address & Contacts
export const EMPLOYEE_ADDRESS_CONTACTS_OPTIONS = 'employee/EMPLOYEE_ADDRESS_CONTACTS_OPTIONS'
export const EMPLOYEE_ADDRESS = 'employee/EMPLOYEE_ADDRESS'
export const EMPLOYEE_ADDRESS_CUSTOM_FIELDS = 'employee/EMPLOYEE_ADDRESS_CUSTOM_FIELDS'
export const EMPLOYEE_ADDRESS_SET = 'employee/EMPLOYEE_ADDRESS_SET'
export const EMPLOYEE_CONTACTS = 'employee/EMPLOYEE_CONTACTS'
export const EMPLOYEE_CONTACT_SET = 'employee/EMPLOYEE_CONTACT_SET'
export const EMPLOYEE_EMERGENCY_CONTACTS = 'employee/EMPLOYEE_EMERGENCY_CONTACTS'
export const EMPLOYEE_EMERGENCY_CONTACT_ADD = 'employee/EMPLOYEE_EMERGENCY_CONTACT_ADD'
export const EMPLOYEE_EMERGENCY_CONTACT_SET = 'employee/EMPLOYEE_EMERGENCY_CONTACT_SET'
export const EMPLOYEE_EMERGENCY_CONTACT_DELETE = 'employee/EMPLOYEE_EMERGENCY_CONTACT_DELETE'
export const EMPLOYEE_ADDRESS_SCHEMA = 'employee/EMPLOYEE_ADDRESS_SCHEMA'
export const EMPLOYEE_CONTACT_SCHEMA = 'employee/EMPLOYEE_CONTACT_SCHEMA'

// Academic Formations
export const EMPLOYEE_EDUCATION_OPTIONS = 'employee-personal/EMPLOYEE_EDUCATION_OPTIONS'
export const EMPLOYEE_EDUCATION_CUSTOM_FIELDS = 'employee/EMPLOYEE_EDUCATION_CUSTOM_FIELDS'
export const EMPLOYEE_EDUCATION = 'employee-personal/EMPLOYEE_EDUCATION'
export const EMPLOYEE_SET_EDUCATION = 'employee-personal/EMPLOYEE_SET_EDUCATION'
export const EMPLOYEE_SET_EDUCATIONS = 'employee-personal/EMPLOYEE_SET_EDUCATIONS'
export const EMPLOYEE_ADD_EDUCATION = 'employee-personal/EMPLOYEE_ADD_EDUCATION'
export const EMPLOYEE_DELETE_EDUCATION = 'employee-personal/EMPLOYEE_DELETE_EDUCATION'
export const EMPLOYEE_FORMATION_SCHEMA = 'employee/EMPLOYEE_FORMATION_SCHEMA'

// Dependents
export const EMPLOYEE_DEPENDENTS = 'employee-dependents/EMPLOYEE_DEPENDENTS'
export const EMPLOYEE_DEPENDENT_CUSTOM_FIELDS = 'employee-dependents/EMPLOYEE_DEPENDENT_CUSTOM_FIELDS'
export const EMPLOYEE_ADD_DEPENDENT = 'employee-dependents/EMPLOYEE_ADD_DEPENDENT'
export const EMPLOYEE_SET_DEPENDENT = 'employee-dependents/EMPLOYEE_SET_DEPENDENT'
export const EMPLOYEE_SET_DEPENDENTS = 'employee-dependents/EMPLOYEE_SET_DEPENDENTS'
export const EMPLOYEE_DELETE_DEPENDENT = 'employee-dependents/EMPLOYEE_DELETE_DEPENDENT'
export const DEPENDENT_RELATIONS_OPTIONS = 'employee-dependents/DEPENDENT_RELATIONS_OPTIONS'
export const EMPLOYEE_DEPENDENT_SCHEMA = 'employee-dependents/EMPLOYEE_DEPENDENT_SCHEMA'

// Bank Account
export const EMPLOYEE_BANK_OPTIONS = 'employee-personal/EMPLOYEE_BANK_OPTIONS'
export const EMPLOYEE_BANK_CUSTOM_FIELDS = 'employee/EMPLOYEE_BANK_CUSTOM_FIELDS'
export const EMPLOYEE_BANK = 'employee-personal/EMPLOYEE_BANK'
export const EMPLOYEE_SET_BANK = 'employee-personal/EMPLOYEE_SET_BANK'
export const EMPLOYEE_SET_BANKS = 'employee-personal/EMPLOYEE_SET_BANKS'
export const EMPLOYEE_ADD_BANK = 'employee-personal/EMPLOYEE_ADD_BANK'
export const EMPLOYEE_DELETE_BANK = 'employee/EMPLOYEE_DELETE_BANK'
export const EMPLOYEE_BANK_SCHEMA = 'employee/EMPLOYEE_BANK_SCHEMA'

// Other Info
export const EMPLOYEE_OTHER_INFO = 'employee-personal/EMPLOYEE_OTHER_INFO'
export const EMPLOYEE_SET_OTHER_INFO = 'employee-personal/EMPLOYEE_SET_OTHER_INFO'

// Documents
export const DOCUMENT_GET_DOCUMENTS = 'document/GET_DOCUMENTS'
export const DOCUMENT_RESET = 'document/RESET'
export const DOCUMENT_SET_RG = 'document/SET_RG'
export const DOCUMENT_SET_CNH = 'document/SET_CNH'
export const DOCUMENT_SET_CPF = 'document/SET_CPF'
export const DOCUMENT_SET_ELECTORAL = 'document/SET_ELECTORAL'
export const DOCUMENT_SET_CTPS = 'document/SET_CTPS'
export const DOCUMENT_SET_RESERVIST = 'document/SET_RESERVIST'
export const DOCUMENT_SET_RESIDENCE = 'document/SET_RESIDENCE'
export const DOCUMENT_SET_STATUS = 'document/SET_STATUS'
export const DOCUMENT_SET_REQUIRED = 'document/SET_REQUIRED'
export const DOCUMENT_SET_OTHER = 'document/SET_OTHER'
export const DOCUMENT_SET_FILES = 'document/SET_FILES'
export const DOCUMENT_SET_CUSTOM = 'document/SET_CUSTOM'
export const DOCUMENT_VALIDATE_DOCUMENTS = 'document/DOCUMENT_VALIDATE_DOCUMENTS'
export const DOCUMENT_VERIFY_DOCUMENTS = 'document/DOCUMENT_VERIFY_DOCUMENTS'
export const DOCUMENT_SCHEMA = 'document/SCHEMA'
export const DOCUMENT_REQUIRED_FIELDS = 'document/REQUIRED_FIELDS'
export const DOCUMENT_CUSTOM_FIELDS = 'document/DOCUMENT_CUSTOM_FIELDS'
export const DOCUMENT_CUSTOM_DOCUMENTS = 'document/DOCUMENT_CUSTOM_DOCUMENTS'

// Contract
export const EMPLOYEE_GET_CONTRACTS = 'contracts/GET_CONTRACTS'
export const EMPLOYEE_GET_CONTRACTS_DOWNLOAD_URL = 'contracts/GET_CONTRACTS_DOWNLOAD_URL'

// Location
export const LOCATION_COUNTRIES = 'location/LOCATION_COUNTRIES'
export const LOCATION_STATES = 'location/LOCATION_STATES'
export const LOCATION_CITIES = 'location/LOCATION_CITIES'

// Authentication
export const AUTH_AUTHENTICATE = 'auth/AUTHENTICATE'
export const AUTH_LOGOUT = 'auth/LOGOUT'

// Admission
export const ADMISSION_INFO = 'admission/INFO'
export const ADMISSION_SINGLE = 'admission/SINGLE'
export const ADMISSION_STEPS = 'admission/STEPS'
export const ADMISSION_HIDE_STEP = 'admission/HIDE_STEP'
export const ADMISSION_SUBMITING = 'admission/SUBMITING'
export const ADMISSION_PREV_STEP = 'admission/PREV_STEP'
export const ADMISSION_NEXT_STEP = 'admission/NEXT_STEP'
export const ADMISSION_SET_STEP = 'admission/SET_STEP'
export const ADMISSION_CLOSE = 'admission/CLOSE'
export const ADMISSION_DELETE_FILE = 'admission/DELETE_FILE'

// System fields
export const EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS = 'systemField/EMPLOYEE_GET_REQUIRED_SYSTEM_FIELDS'
export const EMPLOYEE_SET_REQUIRED_SYSTEM_FIELDS = 'systemField/EMPLOYEE_SET_REQUIRED_SYSTEM_FIELDS'
export const EMPLOYEE_REFRESH_REQUIRED_SYSTEM_FIELDS = 'systemField/EMPLOYEE_REFRESH_REQUIRED_SYSTEM_FIELDS'
